/* eslint-disable react/prop-types */
import React, { useState, useRef } from 'react';
import { IconMessage2 } from '@tabler/icons';
import { Modal } from 'react-bootstrap';
import _ from 'lodash';
import { Carousel, Segmented, Descriptions, Flex, Spin  } from 'antd';
import { FDSA } from '../../utils';

const sortArrayById = items => items.sort((a, b) => b.id - a.id);
const ShowProduct = ({ data }) => {
    let sliderRef = useRef(null);
    const tmps = sortArrayById(data.productTmp);
    const headerTmp = tmps.map((obj, k) => {
        return {
            id: obj.id,
            value: k,
            label: FDSA(obj.createdAt)
        }
    });
    const [segmented, setSegmented] = useState(0);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const onChange = (currentSlide) => {
        setSegmented(currentSlide);
    }
    const settings = {
        dots: false,
        infinite: true
    };
    const CONSTENT_DIV = () => {
        const contentObject = JSON.parse(data.content);
        if (_.isObject(contentObject) && _.size(contentObject)) {
            const contentValue = _.omit(contentObject, ['id', 'product_id', 'sku', 'srp_id', 'srp_latest_id', 'attributes']);
            const itemsContent = !_.isUndefined(contentValue) && _.map(contentValue, (val, k) => {
                return {
                    'id': k,
                    'label': _.upperFirst(_.lowerCase(k)),
                    'children': val
                }
            });
            return <Descriptions title="Descript" items={itemsContent} />
        }
    }
    return <>
        <span className='text-primary me-2 cl-pointer' onClick={handleShow} title='show'><IconMessage2 /></span>
        <Modal show={show} onHide={handleClose} className='modal-blur' size='xl' centered>
            <Modal.Header closeButton>
                <Modal.Title>{data.sku}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Segmented
                    options={headerTmp}
                    value={segmented}
                    onChange={(value) => {
                        sliderRef.goTo(value)
                    }}
                />
                <hr/>
                <Carousel
                    arrows
                    {...settings}
                    ref={slider => {
                        sliderRef = slider;
                    }}
                    afterChange={onChange}>
                    {Array.isArray(tmps) && _.size(tmps) && tmps.map((tmp, k) => {
                        const content = JSON.parse(tmp.content);
                        if(_.isObject(content) && _.size(content)) {
                            const contentValue = _.omit(content, ['id', 'product_id', 'sku', 'srp_id', 'srp_latest_id', 'attributes']);
                            const attributeValue = content.attributes || undefined;
                            let itemsAttribute = [];
                            if (Array.isArray(attributeValue) && !_.isUndefined(attributeValue)) {
                                itemsAttribute = _.map(attributeValue, (val, k) => {
                                    return {
                                        'id': k,
                                        'label': _.upperFirst(_.lowerCase(Object.keys(val)[0])),
                                        'children': Object.values(val)[0],
                                    }
                                });
                            }
                            if (attributeValue.constructor === Object && !_.isUndefined(attributeValue)) {
                                for (const [key, value] of Object.entries(attributeValue)) {
                                    itemsAttribute.push({
                                        'id': key,
                                        'label': _.upperFirst(_.lowerCase(key)),
                                        'children': value,
                                    });
                                }
                            }
                            const itemsContent = !_.isUndefined(contentValue) && _.map(contentValue, (val, k) => {
                                return {
                                    'id': k,
                                    'label': _.upperFirst(_.lowerCase(k)),
                                    'children': val
                                }
                            });
                            return <div key={k}>
                                <Descriptions className='mb-3' title="Attributes" items={itemsAttribute} />
                                <Descriptions title="Descript" items={itemsContent} />
                            </div>
                        } else {
                            return <Flex align="center" gap="middle" key={k} className='text-center'>
                                <Spin size="large" />
                            </Flex>
                        }
                    })}
                    {!_.size(tmps) && _.size(data.content) && CONSTENT_DIV()}
                </Carousel>
            </Modal.Body>
        </Modal>
    </>
}

export default ShowProduct;