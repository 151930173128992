import fetch from 'isomorphic-fetch';
import * as Utils from '../../utils';
import { API_ROOT } from '../../config';

const BASE_URL = API_ROOT;

export const getUsers = async (params) => {
    const response = await fetch(`${BASE_URL}/users?${Utils.ObjectToQueryString(params)}`, {
        method: 'GET',
        headers: Utils.commonHeaders(),
    });
    return Utils.handleNon200s(response);
}

export const getUser = async (params) => {
    const response = await fetch(`${BASE_URL}/users/${params.id}`, {
        method: 'GET',
        headers: Utils.commonHeaders(),
    });
    return Utils.handleNon200s(response);
}

export const putUser = async (data) => {
    try {
        const response = await fetch(`${BASE_URL}/users/${data.id}`, {
            method: 'PUT',
            headers: Utils.commonHeaders(),
            body: JSON.stringify(data)
        });
        return Utils.handleNon200s(response);
    } catch (error) {
        console.log('error: ', error);
        return false;
    }
};

export const putUserStatus = async (data) => {
    try {
        const response = await fetch(`${BASE_URL}/users/status/${data.id}`, {
            method: 'PUT',
            headers: Utils.commonHeaders(),
            body: JSON.stringify(data)
        });
        return Utils.handleNon200s(response);
    } catch (error) {
        console.log('error: ', error);
        return false;
    }
};

export const postUser = async (data) => {
    try {
        const response = await fetch(`${BASE_URL}/users`, {
            method: 'POST',
            headers: Utils.commonHeaders(),
            body: JSON.stringify(data)
        });
        return Utils.handleNon200s(response);
    } catch (error) {
        console.log('error: ', error);
        return false;
    }
};

export const deleteUser = async (params) => {
    const response = await fetch(`${BASE_URL}/users/${params.id}`, {
        method: 'DELETE',
        headers: Utils.commonHeaders(),
    });
    return Utils.handleNon200s(response);
}