import React from 'react'
import { Link } from 'react-router-dom'
import { IconMoodSmile, IconExplicit } from '@tabler/icons'
import _ from 'lodash'
import * as Storage from '../../storage'
import { logout } from '../../utils'
import sonnt from '../../assets/img/avatar/avatar-girl-svgrepo-com.svg';
import { PATH_ROOT } from '../../config';

/**
 * Mobile conponent
 * Takes in the following props
 * @returns 
 */
const Mobile = () => {
    const userInfo = JSON.parse(localStorage.getItem(Storage.USER_INFO))
    const userName = _.upperFirst(userInfo.first_name) + ' ' + _.upperFirst(userInfo.last_name)
    const avatarName = userInfo.avatarName ? `${PATH_ROOT + '/images/avatars/' + userInfo.avatarName}` : sonnt
    return (
        <div className="navbar-nav flex-row d-lg-none">
            <div className="nav-item dropdown d-none d-md-flex me-3">
                <Link to={"#"} className="nav-link px-0" data-bs-toggle="dropdown" tabIndex="-1" aria-label="Show notifications">
                    <svg className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M10 5a2 2 0 0 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6" /><path d="M9 17v1a3 3 0 0 0 6 0v-1" /></svg>
                    <span className="badge bg-red"></span>
                </Link>
                <div className="dropdown-menu dropdown-menu-end dropdown-menu-card">
                    <div className="card">
                        <div className="card-body">
                            Error happened<IconMoodSmile />
                        </div>
                    </div>
                </div>
            </div>
            <div className="nav-item dropdown">
                <Link to={"#"} className="nav-link d-flex lh-1 text-reset p-0" data-bs-toggle="dropdown" aria-label="Open user menu">
                    <span className="avatar avatar-sm" style={{ backgroundImage: "url(" + avatarName + ")" }}></span>
                    <div className="d-none d-xl-block ps-2">
                        <div>{userName}</div>
                        <div className="mt-1 small text-muted">UI Designer</div>
                    </div>
                </Link>
                <div className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                    <Link to={"#"} className="dropdown-item">Set status</Link>
                    <Link to={'/profile'} className="dropdown-item">Profile & account</Link>
                    {userInfo.role >= 2 && <Link to={'/users'} className="dropdown-item">User management</Link>}
                    {userInfo.role === 4 && <Link to={'/entities'} className="dropdown-item">Entity</Link>}
                    <div className="dropdown-divider"></div>
                    <Link to={'/attributes'} className="dropdown-item">Attributes</Link>
                    <span to={"#"} className="dropdown-item cl-pointer" onClick={() => { logout() }}>Logout</span>
                </div>
            </div>
        </div>
    )
};

export default React.memo(Mobile);