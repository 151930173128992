/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import toast, { Toaster } from 'react-hot-toast';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import InputGroup from 'react-bootstrap/InputGroup';
import _ from 'lodash';
import { IconPlus, IconX, IconDownload, IconEyeOff, IconEye } from '@tabler/icons';
import { postUser } from './services';
import { regexSystem } from '../../constant';

const AddUser = ({ fetchDataUser, userInfo, entities }) => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [eye, setEye] = useState(true);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    const onSubmit = async (data) => {
        let dataUser = {
            email: data.email,
            entityId: data.entityId,
            role: data.role,
            first_name: _.trim(data.firstName),
            last_name: _.trim(data.lastName),
            number_phone: data.numberPhone,
            address: _.trim(data.address),
            status: data.status,
            zipcode: data.zipcode,
            description: _.trim(data.description),
            password: data.password
        }
        dataUser = _.pickBy(dataUser, v => !_.isNull(v) && !_.isUndefined(v));
        const response = await postUser(dataUser);
        if (response && _.has(response, 'data')) {
            toast.success('Successfully');
            fetchDataUser();
            setShow(false);
        } else {
            toast.error(response.errMessage);
        }
    }
    const EyeElement = () => {
        let domShow = <span className='link-secondary' title='Show password' data-bs-toggle='tooltip' onClick={() => setEye(false)}><IconEyeOff/></span>;
        if (!eye) {
            domShow = <span className='link-secondary' title='Hidden password' data-bs-toggle='tooltip' onClick={() => setEye(true)}><IconEye/></span>;
        }
        return domShow;
    }

    return (
        <>
            <Button variant='primary' onClick={handleShow}>
                <IconPlus /> Add User
            </Button>
            <Modal show={show} onHide={handleClose} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>Add a new user</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Email address<span className='required ms-1'>(*)</span></Form.Label>
                            <Form.Control
                                type='email'
                                placeholder='email@neotiq.com'
                                autoComplete='email'
                                className={errors.email ? 'input-error': '' }
                                {...register('email', { required: true, pattern: regexSystem.email.pattern.register})}
                                aria-invalid={errors.email ? 'true' : 'false'} 
                            />
                        </Form.Group>
                        <Form.Group className='mb-3'>
                            <Form.Label>Password<span className='required ms-1'>(*)</span></Form.Label>
                            <InputGroup>
                                <Form.Control
                                    type={eye ? 'password' : 'text'}
                                    autoComplete='password'
                                    className={errors.password ? 'input-error': '' }
                                    {...register('password', { required: true, pattern: regexSystem.password.pattern.register})}
                                    aria-invalid={errors.password ? 'true' : 'false'} 
                                />
                                <InputGroup.Text className='cl-pointer'>
                                    {EyeElement()}
                                </InputGroup.Text>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group className='mb-3'>
                            <Form.Label>Entity<span className='required ms-1'>(*)</span></Form.Label>
                            <Form.Select
                                defaultValue={userInfo.entityId}
                                {...register('entityId', { required: true })} >
                                {Array.isArray(entities) && entities.map((e, k) => 
                                    <option key={k} value={e.id}>{e.name}</option>
                                )}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className='mb-3'>
                            <Form.Label>First name</Form.Label>
                            <Form.Control
                                type='text'
                                className={errors.firstName ? 'input-error': '' }
                                {...register('firstName', { required: true })}
                                aria-invalid={errors.firstName ? 'true' : 'false'}
                            />
                        </Form.Group>
                        <Form.Group className='mb-3'>
                            <Form.Label>Last name</Form.Label>
                            <Form.Control
                                type='text'
                                className={errors.lastName ? 'input-error': '' }
                                {...register('lastName', { required: true })}
                                aria-invalid={errors.lastName ? 'true' : 'false'}
                            />
                        </Form.Group>
                        <Form.Group className='mb-3'>
                            <Form.Label>Role</Form.Label>
                            <Form.Select
                                defaultValue={1}
                                {...register('role', { required: true })}
                            >
                                {userInfo.role >= 1 && <option value={1}>User</option>}
                                {userInfo.role >= 2 && <option value={2}>Assistant</option>}
                                {userInfo.role >= 3 && <option value={3}>Admin</option>}
                                {userInfo.role === 4 && <option value={4}>Super Admin</option>}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className='mb-3'>
                            <Form.Label>Phone number</Form.Label>
                            <Form.Control
                                type='text'
                                className={errors.numberPhone ? 'input-error': '' }
                                {...register('numberPhone', { required: true, pattern: regexSystem.phone.pattern.register })}
                                aria-invalid={errors.numberPhone ? 'true' : 'false'}
                            />
                        </Form.Group>
                        <Form.Group className='mb-3'>
                            <Form.Label>ZIP Code</Form.Label>
                            <Form.Control
                                type='text'
                                className={errors.zipcode ? 'input-error': '' }
                                {...register('zipcode', { required: true, pattern: regexSystem.zipCode.pattern.register })}
                                aria-invalid={errors.zipcode ? 'true' : 'false'} 
                            />
                        </Form.Group>
                        <Form.Switch
                            className='mb-3'
                            type='switch'
                            defaultChecked={true}
                            label='Status'
                            {...register('status')}
                        />
                        <Form.Group className='mb-3'>
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                as='textarea'
                                rows={3}
                                {...register('description')}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='default' type='button' onClick={handleClose}><IconX />Cancel</Button>
                    <Button variant='primary ms-auto' type='submit' onClick={handleSubmit(onSubmit)}><IconDownload />Save</Button>
                </Modal.Footer>
            </Modal>
            <Toaster
                position='top-center'
                reverseOrder={false}
            />
        </>
    );
}

export default AddUser;