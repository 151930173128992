/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import toast, { Toaster } from 'react-hot-toast';
import Toast from 'react-bootstrap/Toast';
import { Modal, Form, Button } from 'react-bootstrap';
import { IconFileImport, IconCloudUpload, IconRefresh, IconX, IconAlertTriangle } from '@tabler/icons';
import { CSVReader } from 'react-papaparse';
import _ from 'lodash';
import { ButtonClipLoader } from '../../components/Loading';
import { postProducts, getProducts } from './services';

const buttonRef = React.createRef()
const ImportProduct = ({ fetchDataProduct }) => {
    const { register, handleSubmit } = useForm();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [errorFile, setErrorFile] = useState('');
    const [formData, setFormData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [errShow, setErrShow] = useState(false);
    const [errMessage, setErrMessage] = useState('');

    const toggleErrShow = () => setErrShow(!errShow);
    const onSubmit = async (data) => {
        setIsLoading(true);
        if (!_.isNull(formData)) {
            const textgen = {
                description: data.description,
                meta_description: data.metaDescription,
                meta_title: data.metaTitle,
                meta_keyword: data.metaKeyword
            };
            const dataHeaders = [];
            const dataContent = [...formData.slice(1)];
            const products = [];
            _.forEach(formData[0].data, (value) => {
                dataHeaders.push(_.snakeCase(_.lowerCase(value)));
            }); // get header data
            _.forEach(dataContent, (value) => {
                const attributes = [];
                value.data.map((value, key) => {
                    if (key > 0 && !_.isEmpty(_.trim(value))) {
                        attributes.push({
                            label: dataHeaders[key],
                            code: dataHeaders[key],
                            value: _.trim(value)
                        });
                    }
                })
                products.push({
                    sku: _.trim(value.data[0]),
                    attributes: attributes
                });
            }); // get body data
            const dataRequest = {
                textgen,
                products
            };
            const result = await postProducts(dataRequest);
            if (!_.has(result, 'error')) {
                toast.success('Successfully');
                setErrMessage(false);
                setIsLoading(false);
                handleClose();
                fetchDataProduct();
            } else {
                setIsLoading(false);
                toast.error(result.errMessage || 'Import failed!');
                setErrShow(true);
                setErrMessage(result.errMessage || 'Import failed!');
            }
        } else {
            setIsLoading(false);
        }
    }
    const handleOpenDialog = (e) => {
        if (buttonRef.current) {
            buttonRef.current.open(e);
        }
    }

    const handleOnFileLoad = (data, csvFile) => {
        const pattern = '^.+\\.(csv)$'
        const regex = new RegExp(pattern)
        if (regex.test(csvFile.name)) {
            setErrorFile([]);
            setFormData(data);
        } else {
            setErrorFile('Import has been accept only csv file');
        }
    }

    const handleOnError = (err) => {
        console.log(err)
    }

    const handleOnRemoveFile = (data) => {
        setErrShow(false);
        setErrorFile([]);
        setFormData(data);
    }

    const handleRemoveFile = (e) => {
        // Note that the ref is set async, so it might be null at some point
        if (buttonRef.current) {
            buttonRef.current.removeFile(e)
        }
    }

    return <>
        <span title='import CSV'><IconFileImport className='cl-pointer me-2' color={'#1d60b0'} onClick={handleShow} /></span>
        <Modal show={show} onHide={handleClose} className='modal-blur' centered size='lg'>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Header closeButton>
                    <Modal.Title>Import</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='d-table'>
                        <Form.Switch
                            className='mb-3'
                            type='switch'
                            autoFocus
                            defaultChecked={true}
                            name='description'
                            label='Description'
                            {...register('description')}
                        />
                        <Form.Switch
                            className='mb-3'
                            type='switch'
                            autoFocus
                            defaultChecked={true}
                            name='metaDescription'
                            label='Meta description'
                            {...register('metaDescription')}
                        />
                        <Form.Switch
                            className='mb-3'
                            type='switch'
                            autoFocus
                            defaultChecked={true}
                            name='metaTitle'
                            label='Meta Title'
                            {...register('metaTitle')}
                        />
                        <Form.Switch
                            className='mb-3'
                            type='switch'
                            autoFocus
                            defaultChecked={true}
                            name='metaKeyword'
                            label='Meta Keyword'
                            {...register('metaKeyword')}
                        />
                    </div>
                    <CSVReader
                        accept={'.csv, text/csv'}
                        ref={buttonRef}
                        onFileLoad={handleOnFileLoad}
                        onError={handleOnError}
                        noClick
                        noDrag
                        onRemoveFile={handleOnRemoveFile}
                    >
                        {({ file }) => (
                            <aside style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
                                <Button
                                    type='button'
                                    className='btn-outline-primary'
                                    onClick={handleOpenDialog}
                                    style={{ borderRadius: 0, width: '40%' }}>
                                    <IconCloudUpload /> Browse file .csv
                                </Button>
                                <div style={{
                                    borderWidth: 1,
                                    borderStyle: 'solid',
                                    borderColor: '#ccc',
                                    lineHeight: 2.5,
                                    paddingLeft: '10px',
                                    width: '60%'
                                }}>
                                    {file && file.name}
                                </div>
                                <Button
                                    type='button'
                                    className='btn-outline-secondary'
                                    style={{ borderRadius: 0 }}
                                    onClick={handleRemoveFile}><IconRefresh style={{ margin: '0px' }} /></Button>
                            </aside>
                        )}
                    </CSVReader>
                    <Toast show={errShow} className="alert alert-danger alert-dismissible mt-3" role="alert">
                        <div className="d-flex">
                            <div><IconAlertTriangle className="alert-icon" /></div>
                            <div>
                                <h4 className="alert-title">Something went wrong</h4>
                                <div className="text-muted">{errMessage}</div>
                            </div>
                        </div>
                        <a className="btn-close" onClick={toggleErrShow} />
                    </Toast>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='link-secondary' onClick={handleClose}><IconX />Cancel</Button>
                    <ButtonClipLoader
                        disabled={false}
                        variant='primary'
                        icon={<IconCloudUpload />}
                        isLoading={isLoading}
                        type='submit'
                        label='Import'
                        onClick={handleSubmit(onSubmit)}
                    />
                </Modal.Footer>
            </Form>
        </Modal>
    </>
}

export default ImportProduct;