import React from 'react'
import { connect } from 'react-redux'
import queryString from 'query-string'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import { IconChevronDown, IconChevronUp, IconSearch, IconX } from '@tabler/icons'
import { Table, Spinner, Form } from 'react-bootstrap'
import { NotificationManager } from 'react-notifications'

import { formatDateString, LIMIT, TIME_OUT, INIT_PAGE, PAGE_DISPLAY, SORT_ASC, SORT_DESC } from '../../utils'
import * as ActionTypes from '../../actions/types'
import * as Types from './actions'
import Aside from '../../components/Aside'
import Header from '../../components/Header'
import Paging from '../../components/Paging'
import Footer from '../../components/Footer'

import NoData from '../../components/NoData'
import ConfirmRemove from './components/ConfirmRemove'
import ModalAdd from './modalAdd'
import ModalEdit from './modalEdit'

/**
 * Categories page
 * constructor: data userinfo
 */
class Categories extends React.Component {
    constructor(props) {
        super(props)
        const userInfo = _.get(props, "resultMe.detail.result.userInfo", _.get(props, "resultAuth.detail.result.userInfo", null))
        const QUERY_STRING = queryString.parse(props.location.search) || null
        this.state = {
            userInfo: userInfo,
            error: false,
            errorMessage: null,
            isLoading: false,
            location: props.location,
            searchText: "",
            sortBy: "createdAt",
            order: SORT_DESC,
            getRefreshCategories: this.getRefreshCategories.bind(this)
        }
        this.requireMe(userInfo, this.state)
        this.getInitList(_.isNaN(_.parseInt(_.get(QUERY_STRING, 'page', INIT_PAGE))) ? INIT_PAGE : _.parseInt(_.get(QUERY_STRING, 'page', INIT_PAGE)), this.state)
    }

    /**
     * Init data display information
     */
    requireMe(userInfo, state) {
        if (_.isNull(userInfo)) {
            this.props.dispatch({ type: ActionTypes.GET_ME_REQUESTED })
            state.isLoading = true
        }
    }

    getInitList = (pageActive, state) => {
        const QUERY_LIST = {
            sortBy: state.sortBy,
            order: state.order,
            limit: LIMIT,
            offset: (pageActive - 1) * LIMIT
        }
        state.pageActive = pageActive
        state.queyList = QUERY_LIST
        this.getActionList(QUERY_LIST)
    }

    getList = (pageActive, sortBy = null, order = null) => {
        const addOrder = order ? (this.state.order === SORT_DESC ? SORT_ASC : SORT_DESC) : this.state.order
        const addSortBy = sortBy ? sortBy : this.state.sortBy
        const QUERY_LIST = {
            sortBy: addSortBy,
            order: addOrder,
            limit: LIMIT,
            offset: (pageActive - 1) * LIMIT
        }
        if (this.state.searchText) {
            QUERY_LIST["code"] = this.state.searchText
            QUERY_LIST["name"] = this.state.searchText
        }

        this.setState({
            order: addOrder,
            sortBy: addSortBy,
            queyList: QUERY_LIST,
            pageActive: pageActive
        })
        this.getActionList(QUERY_LIST)
    }

    getActionList = (queyList) => {
        this.props.dispatch({ type: Types.CATEGORY_LIST.REQUESTED, detail: queyList })
    }

    /**
     * Receive props data
     * @param {*} props
     * @param {*} state 
     */
    static getDerivedStateFromProps(props, state) {
        const { resultAuth, resultMe, resultCategory } = props
        const userInfo = resultMe.detail ? _.get(resultMe, "detail.result.userInfo", null) : _.get(resultAuth, "detail.result.userInfo", null)
        if (!_.isNull(userInfo)) {
            state.isLoading = false
            state.userInfo = userInfo
        }
        const { ListCategory, CreateCategory, UpdateCategory, RemoveCategory } = resultCategory

        // Get list record
        var listCate = _.get(ListCategory, 'detail.result', null)
        if (ListCategory.error) {
            state.isLoading = false
            state.error = true
            state.errorMessage = ListCategory.message
        }
        if (_.has(listCate, 'data')) {
            state.listCate = listCate.data
            state.pagination = listCate.pagination
        }

        // Create a new record
        const newAttr = _.get(CreateCategory, 'detail', null)
        if (CreateCategory.error) {
            state.isLoading = false
            state.error = true
            state.errorMessage = CreateCategory.message
        }
        if (_.has(newAttr, 'data')) {
            state.isLoading = false
            state.successCreate = true
            state.getRefreshCategories(listCate, state, props)
        }

        // Put the record
        const editAttr = _.get(UpdateCategory, 'detail', null)
        if (UpdateCategory.error) {
            state.isLoading = false
            state.error = true
            state.errorMessage = UpdateCategory.message
        }
        if (_.has(editAttr, 'data')) {
            state.isLoading = false
            state.successUpdate = true
            state.getRefreshCategories(listCate, state, props)
        }

        // Remove the record
        const removeAttr = _.get(RemoveCategory, 'detail', null)
        if (RemoveCategory.error) {
            state.isLoading = false
            state.error = true
            state.errorMessage = RemoveCategory.message
        }
        if (_.has(removeAttr, 'data')) {
            state.isLoading = false
            state.successRemove = true
            state.getRefreshCategories(listCate, state, props)
        }

        return state
    }


    /**
     * refresh data in getDerivedStateFromProps
     * @param {*} listCate 
     * @param {*} state 
     * @param {*} props 
     * @returns 
     */
    getRefreshCategories = (listCate, state, props) => {
        let listData = [...listCate.data]
        let category_query = state.queyList
        if (_.has(state, "searchText")) {
            category_query.label = state.searchText
            category_query.value = state.searchText
        }
        if (listData.length <= 1) {
            const page = _.parseInt(_.get(state, "pageActive", null))
            if (page > 1) {
                const pageActive = page - 1
                props.history.push({
                    pathname: state.location.pathname,
                    search: "?page=" + (page - 1)
                })
                const QUERY_LIST = {
                    sortBy: state.sortBy,
                    order: state.order,
                    limit: LIMIT,
                    offset: (pageActive - 1) * LIMIT
                }
                state.queyList = QUERY_LIST
                state.pageActive = pageActive
                props.dispatch({ type: Types.CATEGORY_LIST.REQUESTED, detail: category_query })
            }
        } else {
            props.dispatch({ type: Types.CATEGORY_LIST.REQUESTED, detail: category_query })
        }
        return state;
    }

    /**
     * handle remove item
     * @param {*} id 
     */
    handleRemove = (id) => {
        const detail = { id: id }
        this.props.dispatch({ type: Types.CATEGORY_REMOVE.REQUESTED, detail })
    }

    /**
     * Search input form
     * @param {*} event 
     */
    handleSearch = (event) => {
        event.preventDefault()
        if (_.has(this.state, "searchText")) {
            const category = {
                code: _.get(this.state, "searchText"),
                name: _.get(this.state, "searchText"),
                pageActive: INIT_PAGE
            }
            this.props.history.push({
                pathname: this.state.location.pathname
            })
            this.props.dispatch({ type: Types.CATEGORY_LIST.REQUESTED, detail: category })
        }
    }

    /**
     * handle change input search text
     * @param {*} event 
     */
    handleChange = (event) => {
        const searchText = event.target.value
        this.setState({
            searchText: searchText
        })
    }

    /**
     * reset state search text empty
     */
    handleReset = () => {
        const resetText = ""
        this.setState({
            searchText: resetText,
            pageActive: INIT_PAGE
        })
        this.props.history.push({ pathname: this.state.location.pathname })
        this.props.dispatch({ type: Types.CATEGORY_LIST.REQUESTED })
    }

    /**
     * filter sortBy text
     * @param {*} sortBy 
     */
    handleSort = (sortBy) => {
        if (sortBy) {
            this.props.history.push({ pathname: this.state.location.pathname })
            this.getList(INIT_PAGE, sortBy, this.state.order)
        }
    }

    /**
     * control item sortBy
     * @param {*} item 
     * @returns 
     */
    sortItem = (item) => {
        let itemDom = <IconChevronDown size="12" stroke="1" />
        if (item === this.state.sortBy) {
            if (this.state.order === SORT_ASC) {
                itemDom = <IconChevronUp size="12" stroke="1" />
            }
        }
        return itemDom
    }

    componentDidUpdate() {
        const { ListCategory, CreateCategory, UpdateCategory, RemoveCategory } = this.props.resultCategory

        if (_.get(ListCategory, "error", false)) {
            NotificationManager.error(this.state.errorMessage, '', TIME_OUT)
            this.props.dispatch({ type: Types.CATEGORY_LIST.RESET })
            this.setState({ error: null })
        }
        if (_.get(CreateCategory, "error", false)) {
            NotificationManager.error(this.state.errorMessage, '', TIME_OUT)
            this.props.dispatch({ type: Types.CATEGORY_CREATE.RESET })
            this.setState({ error: null })
        }
        if (_.get(UpdateCategory, "error", false)) {
            NotificationManager.error(this.state.errorMessage, '', TIME_OUT)
            this.props.dispatch({ type: Types.CATEGORY_UPDATE.RESET })
            this.setState({ error: null })
        }
        if (_.get(RemoveCategory, "error", false)) {
            NotificationManager.error(this.state.errorMessage, '', TIME_OUT)
            this.props.dispatch({ type: Types.CATEGORY_REMOVE.RESET })
            this.setState({ error: null })
        }

        if (_.get(this.state, "successCreate", false)) {
            NotificationManager.success(CreateCategory.message, '', TIME_OUT)
            this.props.dispatch({ type: Types.CATEGORY_CREATE.RESET })
            this.setState({ successCreate: null })
        }

        if (_.get(this.state, "successUpdate", false)) {
            NotificationManager.success(UpdateCategory.message, '', TIME_OUT)
            this.props.dispatch({ type: Types.CATEGORY_UPDATE.RESET })
            this.setState({ successUpdate: null })
        }

        if (_.get(this.state, "successRemove", false)) {
            NotificationManager.success(RemoveCategory.message, '', TIME_OUT)
            this.props.dispatch({ type: Types.CATEGORY_REMOVE.RESET })
            this.setState({ successRemove: null })
        }

    }

    /* --------------------------------------------
            RENDERING
    -------------------------------------------- */
    render() {
        const { match } = this.props
        const { listCate, pagination, location, isLoading, searchText } = this.state
        return (
            <React.Fragment>
                <Aside match={match} />
                <Header />
                <div className="page-wrapper">
                    <div className="page-body">
                        <div className="container-xl">
                            <div className="row row-deck row-cards">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h3 className="card-title">System Category List</h3>
                                        </div>
                                        <div className="card-body border-bottom py-3">
                                            <div className="d-flex">
                                                <Form onSubmit={this.handleSearch} className="ms-auto text-muted ms-3 d-inline-block me-3">
                                                    <div className="input-group input-group-flat">
                                                        <input type="text" name="search" className="form-control" placeholder="Search category…" onChange={this.handleChange} value={searchText} />
                                                        <span className="input-group-text">
                                                            {_.get(this.state, "searchText") && <Link className="link-secondary" to={"#"} title="Clear search" data-bs-toggle="tooltip" onClick={this.handleReset}><IconX /></Link>}
                                                            <Link className="link-secondary" to={"#"} onClick={this.handleSearch}><IconSearch /></Link>
                                                        </span>
                                                    </div>
                                                </Form>
                                                <ModalAdd dispatch={this.props.dispatch} />
                                            </div>
                                        </div>
                                        <div className="table-responsive" style={{ minHeight: '300px' }}>
                                            <Table className="card-table table-vcenter text-nowrap datatable">
                                                <thead>
                                                    <tr>
                                                        <th className="w-1"><Link to={"#"} onClick={() => this.handleSort('id')}>{this.sortItem('id')}</Link></th>
                                                        <th>Name<Link to={"#"} onClick={() => this.handleSort('name')}>{this.sortItem('name')}</Link></th>
                                                        <th>Code<Link to={"#"} onClick={() => this.handleSort('code')}>{this.sortItem('code')}</Link></th>
                                                        <th>Creation date<Link to={"#"} onClick={() => this.handleSort('createdAt')}>{this.sortItem('createdAt')}</Link></th>
                                                        <th>Updated<Link to={"#"} onClick={() => this.handleSort('updatedAt')}>{this.sortItem('updatedAt')}</Link></th>
                                                        <th>Status</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {Array.isArray(listCate) && listCate.length > 0 ? listCate.map((row, i) =>
                                                        <tr key={row.id}>
                                                            <td><span className="text-muted">{"A-" + row.id}</span></td>
                                                            <td>{row.name}</td>
                                                            <td>{row.code}</td>
                                                            <td>{formatDateString(row.createdAt)}</td>
                                                            <td>{formatDateString(row.updatedAt)}</td>
                                                            <td><span className="badge bg-success me-1"></span></td>
                                                            <td>
                                                                <ModalEdit
                                                                    category={row}
                                                                    dispatch={this.props.dispatch}
                                                                />
                                                                <ConfirmRemove
                                                                    title="Are you sure?"
                                                                    message="Do you really want to remove record."
                                                                    id={row.id}
                                                                    dispatch={this.props.dispatch}
                                                                />
                                                            </td>
                                                        </tr>
                                                    ): <NoData/>}
                                                </tbody>
                                            </Table>

                                        </div>
                                        <div className="card-footer d-flex align-items-center">
                                            {!_.isUndefined(pagination) &&
                                            <Paging
                                                activePage={pagination.offset / LIMIT + 1}
                                                itemsCountPerPage={pagination.limit || LIMIT}
                                                totalItemsCount={pagination.totalCount}
                                                pageRangeDisplayed={PAGE_DISPLAY}
                                                requestList={this.getList}
                                                location={location}
                                                history={this.props.history}
                                            />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {isLoading && <div className="loading">
                            <Spinner animation="border" role="status" />
                        </div>}
                    </div>
                    <Footer />
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    resultMe: state.me,
    resultAuth: state.auth,
    resultCategory: state.category
})

export default connect(mapStateToProps)(Categories)