/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { IconMessage2, IconMail, IconWorldWww, IconZip, IconMapPin } from '@tabler/icons';
import { IoToggle, IoToggleOutline } from 'react-icons/io5';
import { Modal, Button } from 'react-bootstrap';
import { formatDateString } from '../../utils';

const ShowEntity = ({ data }) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => {
        setIsOpen(!isOpen);
    }
    return <>
        <span className='text-primary me-2 cl-pointer' onClick={toggle}><IconMessage2 /></span>
        <Modal show={isOpen} onHide={toggle} className='modal-blur' size='sm' centered>
            <div>
                <button type='button' className='btn-close' onClick={toggle}></button>
                <div className='modal-body py-4'>
                    <h3 className='text-center'>{data.name}</h3>
                    <div className='text-muted'>
                        {data.logo && <p>{data.logo}</p>}
                        {data.website && <p><IconWorldWww/>: <Link to={data.website}>{data.website}</Link></p>}
                        {data.email && <p><IconMail/>: {data.email}</p>}
                        {data.short_name && <p>{data.short_name}</p>}
                        {data.number_phone && <p>Phone number: {data.number_phone}</p>}
                        {data.zipcode && <p><IconZip/>: {data.zipcode}</p>}
                        {data.address && <p><IconMapPin/>: {data.address}</p>}
                        {<p>{data.status ? <IoToggle size={25} color='green' /> : <IoToggleOutline size={25} color='gray' />}</p>}
                    </div>
                </div>
                <div className='modal-footer'>
                    <div className='w-100'>
                        <div className='row'>
                            <div className='col'>
                                <Button variant='white w-100' onClick={toggle}>Close</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    </>
}

export default ShowEntity;