/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import _ from 'lodash';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { IconCircleMinus, IconRowInsertBottom } from '@tabler/icons';

const AddOptional = ({updateOptional, listOptional}) => {
    const [optional, setOptional] = useState(listOptional);
    const addOptional = () => {
        const dataUpdate = [...optional, {
            value: '',
            err_value: false
        }];
        setOptional(dataUpdate);
        updateOptional(dataUpdate)
        
    }
    const deleteOptional = (e, idx) => {
        if (_.size(optional) > 1) {
            const dataUpdate = optional.filter(function (item, key) {
                return key !== idx;
            })
            setOptional(dataUpdate);
            updateOptional([...dataUpdate]);
        }
    }
    const handleChange = (event) => {
        let data_name = event.target.name.split('-');
        if (data_name[1]) {
            const optId = data_name[1];
            const dataOptional = [...optional];
            if (['value'].includes(data_name[0])) {
                if (event.target.value.trim() === '') {
                    dataOptional[optId]['err_' + data_name[0]] = true;
                } else {
                    dataOptional[optId]['err_' + data_name[0]] = false;
                }
                dataOptional[optId][data_name[0]] = event.target.value;
                setOptional(dataOptional);
            }
        }
    }
    return (
        <>
            {Array.isArray(optional) && _.size(optional) > 0 && optional.map((val, idx) => {
                let valueId = `value-${idx}`;
                return <div key={idx} className='form-attributes'>
                    <InputGroup className="mt-2">
                        <InputGroup.Text>Value<span className='required ms-1'>(*)</span></InputGroup.Text>
                        <Form.Control name={valueId} aria-describedby={valueId} className={`form-control round ${optional[idx].err_value && 'is-invalid'}`} value={optional[idx].value} onChange={(e) => handleChange(e)} />
                        <InputGroup.Text className='cl-pointer col-auto' onClick={(e) => deleteOptional(e, idx)}><IconCircleMinus /></InputGroup.Text>
                    </InputGroup>
                </div>
            })}
            <span><IconRowInsertBottom className='link-primary cl-pointer mt-2' size={24} onClick={addOptional}/></span>
        </>
    );
}

export default AddOptional;