import React from 'react'
import { connect } from 'react-redux'
import queryString from 'query-string'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import { IconChevronUp, IconSearch, IconX, IconChevronDown} from '@tabler/icons'
import { Table, Spinner, Form } from 'react-bootstrap'
import { NotificationManager } from 'react-notifications'

import { formatDateString, LIMIT, TIME_OUT, INIT_PAGE, PAGE_DISPLAY, SORT_ASC, SORT_DESC } from '../../utils'
import * as ActionTypes from '../../actions/types'
import * as Types from './actions.js'
import * as TypeTemplates from '../Templates/actions'
import Aside from '../../components/Aside'
import Header from '../../components/Header'
import Paging from '../../components/Paging'
import Footer from '../../components/Footer'
import ConfirmRemove from './ConfirmRemove'
import ModalImport from './modalImport'
import ModalExport from './modalExport'
// import ModalEdit from './modalEdit'

/**
 * Imports page
 * constructor: data userinfo
 */
class Imports extends React.Component {
    constructor(props) {
        super(props);
        const userInfo = _.get(props, "resultMe.detail.result.userInfo", _.get(props, "resultAuth.detail.result.userInfo", null));
        const QUERY_STRING = queryString.parse(props.location.search) || null
        this.state = {
            userInfo: userInfo,
            error: false,
            errorMessage: null,
            isLoading: false,
            location: props.location,
            searchText: "",
            sortBy: "createdAt",
            order: SORT_DESC,
            getRefreshGenerators: this.getRefreshGenerators.bind(this)
        };
        this.requireMe(userInfo, this.state)
        this.getList(_.isNaN(_.parseInt(_.get(QUERY_STRING, 'page', INIT_PAGE))) ? INIT_PAGE : _.parseInt(_.get(QUERY_STRING, 'page', INIT_PAGE)), this.state)
        this.getListTemplate()
    }

    /**
     * Init data display information
     */
    requireMe(userInfo, state) {
        if (_.isNull(userInfo)) {
            this.props.dispatch({ type: ActionTypes.GET_ME_REQUESTED })
            state.isLoading = true
        }
    }

    getList = (pageActive, state) => {
        const QUERY_LIST = {
            sortBy: 'createdAt',
            order: 'DESC',
            limit: LIMIT,
            offset: (pageActive - 1) * LIMIT
        }
        if (state) {
            state.queyList = QUERY_LIST
        } else {
            this.setState({ queyList: QUERY_LIST })
        }
        this.props.dispatch({ type: Types.CSV_LIST.REQUESTED, detail: QUERY_LIST })
    }

    getListTemplate = () => {
        const QUERY_LIST = {
            sortBy: 'createdAt',
            order: 'DESC',
            limit: 1000,
            offset: 0
        }
        this.props.dispatch({ type: TypeTemplates.TEMPLATE_LIST.REQUESTED, detail: QUERY_LIST })
    }

    /**
     * Receive props data
     * @param {*} props
     * @param {*} state 
     */
    static getDerivedStateFromProps(props, state) {
        const { resultAuth, resultMe, resultImport, resultTemplate, resultAttribute } = props;
        const userInfo = resultMe.detail ? _.get(resultMe, "detail.result.userInfo", null) : _.get(resultAuth, "detail.result.userInfo", null)
        if (!_.isNull(userInfo)) {
            state.isLoading = false;
            state.userInfo = userInfo;
        }
        const { ListCsv, CreateCsv, RemoveCsv } = resultImport

        // Get list record
        var listFile = _.get(ListCsv, 'detail.result', null)
        if (ListCsv.error) {
            state.isLoading = false
            state.error = true
            state.errorMessage = ListCsv.message
        }
        if (_.has(listFile, 'data')) {
            state.listFile = listFile.data
            state.pagination = listFile.pagination
        }

        // Create a new record
        const newCsv = _.get(CreateCsv, 'detail', null)
        if (CreateCsv.error) {
            state.isLoading = false
            state.error = true
            state.errorMessage = CreateCsv.message
        }
        if (_.has(newCsv, 'data')) {
            state.isLoading = false
            state.successCreate = true
            state.getRefreshGenerators(listFile, state, props)
        }

        // Remove the record
        const removeFile = _.get(RemoveCsv, 'detail', null)
        if (RemoveCsv.error) {
            state.isLoading = false
            state.error = true
            state.errorMessage = RemoveCsv.message;
        }
        if (_.has(removeFile, 'data')) {
            state.isLoading = false
            state.successRemove = true
            state.getRefreshGenerators(listFile, state, props)
        }

        // Get list template
        const dataTemplate = _.get(resultTemplate, 'ListTemplate.detail.result.data', null)
        if (Array.isArray(dataTemplate) && dataTemplate.length > 0) {
            state.listTemplate = dataTemplate
        }

        // Get list attribute
        const dataAttribute = _.get(resultAttribute, 'ListAttribute.detail.result.data', null)
        if (Array.isArray(dataAttribute) && dataAttribute.length > 0) {
            state.listAttribute = dataAttribute
        }
        return state
    }


    /**
     * refresh data in getDerivedStateFromProps
     * @param {*} listFile 
     * @param {*} state 
     * @param {*} props 
     * @returns 
     */
    getRefreshGenerators = (listFile, state, props) => {
        let listData = [...listFile.data]
        let generator_query = state.queyList
        if (_.has(state, "searchText")) {
            generator_query.label = state.searchText
            generator_query.value = state.searchText
        }
        if (listData.length <= 1) {
            const page = _.parseInt(_.get(state, "pageActive", null))
            if (page > 1) {
                const pageActive = page - 1
                props.history.push({
                    pathname: state.location.pathname,
                    search: "?page=" + (page - 1)
                })
                const QUERY_LIST = {
                    sortBy: state.sortBy,
                    order: state.order,
                    limit: LIMIT,
                    offset: (pageActive - 1) * LIMIT
                }
                state.queyList = QUERY_LIST
                state.pageActive = pageActive
                props.dispatch({ type: Types.CSV_LIST.REQUESTED, detail: generator_query })
            }
        } else {
            props.dispatch({ type: Types.CSV_LIST.REQUESTED, detail: generator_query })
        }
        return state;
    }


    handleRemove = (id) => {
        const detail = { id: id }
        this.props.dispatch({ type: Types.CSV_REMOVE.REQUESTED, detail })
    }

    handleSearch = (event) => {
        event.preventDefault()
        if (_.has(this.state, "searchText")) {
            const csv = {
                fileName: _.get(this.state, "searchText"),
                name: _.get(this.state, "searchText"),
                pageActive: INIT_PAGE
            }
            this.props.history.push({
                pathname: this.state.location.pathname
            })
            this.props.dispatch({ type: Types.CSV_LIST.REQUESTED, detail: csv })
        }
    }

    /**
     * handle change input search text
     * @param {*} event 
     */
    handleChange = (event) => {
        const searchText = event.target.value
        this.setState({
            searchText: searchText
        })
    }


    /**
     * reset state search text empty
     */
    handleReset = () => {
        const resetText = ""
        this.setState({
            searchText: resetText,
            pageActive: INIT_PAGE
        })
        this.props.history.push({ pathname: this.state.location.pathname })
        this.props.dispatch({ type: Types.CSV_LIST.REQUESTED })
    }

    /**
     * filter sortBy text
     * @param {*} sortBy 
     */
    handleSort = (sortBy) => {
        if (sortBy) {
            this.props.history.push({ pathname: this.state.location.pathname })
            this.getList(INIT_PAGE, sortBy, this.state.order)
        }
    }


    /**
     * control item sortBy
     * @param {*} item 
     * @returns 
     */
    sortItem = (item) => {
        let itemDom = <IconChevronDown size="12" stroke="1" />
        if (item === this.state.sortBy) {
            if (this.state.order === SORT_ASC) {
                itemDom = <IconChevronUp size="12" stroke="1" />
            }
        }
        return itemDom
    }

    componentDidUpdate() {
        const { ListCsv, CreateCsv, RemoveCsv } = this.props.resultImport

        if (_.get(ListCsv,"error", false)) {
            NotificationManager.error(this.state.errorMessage, '', TIME_OUT)
            this.props.dispatch({ type: Types.CSV_LIST.RESET })
            this.setState({ error: null })
        }
        if (_.get(CreateCsv, "error", false)) {
            NotificationManager.error(this.state.errorMessage, '', TIME_OUT)
            this.props.dispatch({ type: Types.CSV_CREATE.RESET })
            this.setState({ error: null })
        }
        if (_.get(RemoveCsv, "error", false)) {
            NotificationManager.error(this.state.errorMessage, '', TIME_OUT)
            this.props.dispatch({ type: Types.CSV_REMOVE.RESET })
            this.setState({ error: null })
        }
        
        if (_.get(this.state, 'successCreate')) {
            NotificationManager.success(CreateCsv.message, '', TIME_OUT)
            this.props.dispatch({ type: Types.CSV_CREATE.RESET })
            this.setState({ successCreate: null })
        }

        if (_.get(this.state, 'successRemove')) {
            NotificationManager.success(RemoveCsv.message, '', TIME_OUT)
            this.props.dispatch({ type: Types.CSV_REMOVE.RESET })
            this.setState({ successRemove: null })
        }

        if (_.get(this.state, 'successExport')) {
            this.props.dispatch({ type: Types.CSV_EXPORT.RESET })
            this.setState({ successExport: null })
        }
    }

    /* --------------------------------------------
            RENDERING
    -------------------------------------------- */
    render() {
        const { match } = this.props
        const { listFile, pagination, location, isLoading, listAttribute, listTemplate, searchText } = this.state
        return (
            <React.Fragment>
                <Aside match={match} />
                <Header />
                <div className="page-wrapper">
                    <div className="page-body">
                        <div className="container-xl">
                            <div className="row row-deck row-cards">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h3 className="card-title">List of imported csv files</h3>
                                        </div>
                                        <div className="card-body border-bottom py-3">
                                            <div className="d-flex">
                                                <Form onSubmit={this.handleSearch} className="ms-auto text-muted ms-3 d-inline-block me-3">
                                                    <div className="input-group input-group-flat">
                                                        <input type="text" name="search" className="form-control" placeholder="Search file…" onChange={this.handleChange} value={searchText} />
                                                        <span className="input-group-text">
                                                            {_.get(this.state, "searchText") && <Link className="link-secondary" to={"#"} title="Clear search" data-bs-toggle="tooltip" onClick={this.handleReset}><IconX /></Link>}
                                                            <Link className="link-secondary" to={"#"} onClick={this.handleSearch}><IconSearch /></Link>
                                                        </span>
                                                    </div>
                                                </Form>
                                                <ModalImport attributes={listAttribute} dispatch={this.props.dispatch} />
                                            </div>
                                        </div>
                                        <div className="table-responsive" style={{ minHeight: '300px' }}>
                                            <Table className="card-table table-vcenter text-nowrap datatable">
                                                <thead>
                                                    <tr>
                                                        <th className="w-1"><IconChevronUp /></th>
                                                        <th>File name</th>
                                                        <th>Creation date</th>
                                                        <th>Status</th>
                                                        <th className="text-center">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {Array.isArray(listFile) && listFile.map((row, i) =>
                                                        <tr key={i}>
                                                            <td><span className="text-muted">{"G-" + row.id}</span></td>
                                                            <td>{row.file_name}</td>
                                                            <td>{formatDateString(row.createdAt)}</td>
                                                            <td><span className="badge bg-success me-1"></span></td>
                                                            <td className="text-center">
                                                                <ConfirmRemove
                                                                    title="Are you sure?"
                                                                    message="Do you really want to remove record."
                                                                    id={row.id}
                                                                    dispatch={this.props.dispatch} />
                                                                {listTemplate && <ModalExport
                                                                    csv={row}
                                                                    templates={listTemplate} />}
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </Table>

                                        </div>
                                        <div className="card-footer d-flex align-items-center">
                                            {!_.isUndefined(pagination) &&
                                                <Paging
                                                    activePage={pagination.offset / LIMIT + 1}
                                                    itemsCountPerPage={pagination.limit || LIMIT}
                                                    totalItemsCount={pagination.totalCount}
                                                    pageRangeDisplayed={PAGE_DISPLAY}
                                                    requestList={this.getList}
                                                    location={location}
                                                    history={this.props.history}
                                                />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {isLoading && <div className="loading">
                            <Spinner animation="border" role="status" />
                        </div>}
                    </div>
                    <Footer />
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    resultMe: state.me,
    resultAuth: state.auth,
    resultImport: state.importCsv,
    resultAttribute: state.attribute,
    resultTemplate: state.template,
})

export default connect(mapStateToProps)(Imports);