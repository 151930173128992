/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { IconCloudUpload, IconRefresh, IconX } from '@tabler/icons';
import { CSVReader } from 'react-papaparse';
import _ from 'lodash';
import { ButtonClipLoader } from '../../components/Loading';
import { importAttribute, getAttributes } from './services';

const buttonRef = React.createRef()

const ImportAttribute = ({fetchDataAttribute}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [fileName, setFileName] = useState('');
    const [formData, setFormData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const toggle = () => {
        setIsOpen(!isOpen);
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const csv = { fileName, formData };
        const result = await importAttribute(csv);
        if (result) {
            setIsLoading(false);
            toggle();
            fetchDataAttribute();
        }
    }
    const handleOpenDialog = (e) => {
        // Note that the ref is set async, so it might be null at some point
        if (buttonRef.current) {
            buttonRef.current.open(e);
        }
    }

    const handleOnFileLoad = (data, csvFile) => {
        const pattern = "^.+\\.(csv)$"
        const regex = new RegExp(pattern)
        if (regex.test(csvFile.name)) {
            setErrors([]);
            setFileName(csvFile.name);
            setFormData(data);
        } else {
            setErrors('Import has been accept only csv file');
        }
    }

    const handleOnError = (err) => {
        console.log(err)
    }

    const handleOnRemoveFile = (data) => {
        setErrors([]);
        setFormData(data);
    }

    const handleRemoveFile = (e) => {
        // Note that the ref is set async, so it might be null at some point
        if (buttonRef.current) {
            buttonRef.current.removeFile(e)
        }
    }

    return <>
        <Button className="pull-right me-2" onClick={toggle}>
            <IconCloudUpload />Import
        </Button>
        <Modal show={isOpen} onHide={toggle} className="modal-blur" centered size="lg">
            <Form onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>Import</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CSVReader
                        accept={'.csv, text/csv'}
                        ref={buttonRef}
                        onFileLoad={handleOnFileLoad}
                        onError={handleOnError}
                        noClick
                        noDrag
                        onRemoveFile={handleOnRemoveFile}
                    >
                        {({ file }) => (
                            <aside style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
                                <Button
                                    type='button'
                                    className='btn-outline-primary'
                                    onClick={ handleOpenDialog }
                                    style={{ borderRadius: 0, width: '40%' }}>
                                    <IconCloudUpload/> Browse file .csv
                                </Button>
                                <div style={{
                                    borderWidth: 1,
                                    borderStyle: 'solid',
                                    borderColor: '#ccc',
                                    lineHeight: 2.5,
                                    paddingLeft: '10px',
                                    width: '60%'
                                }}>
                                    {file && file.name}
                                </div>
                                <Button
                                    type='button'
                                    className='btn-outline-secondary'
                                    style={{ borderRadius: 0 }}
                                    onClick={handleRemoveFile}><IconRefresh style={{ margin: '0px' }} /></Button>
                            </aside>
                        )}
                    </CSVReader>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='link-secondary' onClick={toggle}><IconX />Cancel</Button>
                    <ButtonClipLoader
                        disabled={false}
                        variant='primary'
                        icon={<IconCloudUpload />}
                        isLoading={isLoading}
                        type='submit'
                        label='Import'
                    />
                </Modal.Footer>
            </Form>
        </Modal>
    </>
}

export default ImportAttribute;