/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import toast, { Toaster } from 'react-hot-toast';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import InputGroup from 'react-bootstrap/InputGroup';
import _ from 'lodash';
import { IconPlus, IconX, IconDownload, IconRowInsertBottom, IconCircleMinus } from '@tabler/icons';
import { postProduct } from './services';
import { typeObject } from '../../constant';
const AddProduct = ({ fetchDataProduct, listA }) => {
    const { register, handleSubmit, setError, formState: { errors } } = useForm();
    const [attributes, setAttributes] = useState([]);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const getInitAttribute = (attrInit) => {
        let objectFA = attrInit;
        if ([typeObject.SIMPLE_SELECT, typeObject.MULTI_SELECT].includes(attrInit.typeData)) {
            objectFA = { ...attrInit, value: attrInit.selectOption[0].value }
        } else if ([typeObject.BOOLEAN].includes(attrInit.typeData)) {
            objectFA = { ...attrInit, value: 'YES' }
        } 
        return objectFA;
    }
    useEffect(() => {
        if (show) {
            if (!_.isUndefined(listA[0])) {
                let objectFA = getInitAttribute(listA[0]);
                setAttributes([objectFA]);
            } else {
                setAttributes([]);
            }
        }
    }, [listA, show]);
    const addAttribute = () => {
        let objectFA = getInitAttribute(listA[0]);
        const dataUpdate = [...attributes, objectFA];
        setAttributes(dataUpdate);
    }
    const deleteAttribute = (e, idx) => {
        if (_.size(attributes) > 1) {
            const dataUpdate = attributes.filter(function (item, key) {
                return key !== idx;
            })
            setAttributes(dataUpdate);
        }
    }
    const handleChange = (event) => {
        let data_name = event.target.name.split('-');
        if (data_name[1]) {
            const attrId = data_name[1];
            const dataAttribute = [...attributes];
            if (['value'].includes(data_name[0])) {
                if (event.target.value.trim() === '') {
                    dataAttribute[attrId]['err_' + data_name[0]] = true;
                } else {
                    dataAttribute[attrId]['err_' + data_name[0]] = false;
                }
                dataAttribute[attrId][data_name[0]] = event.target.value;
                setAttributes(dataAttribute);
            }
        }
    }
    const handleAttribute = (event, idx) => {
        const attr = JSON.parse(event.target.options[event.target.selectedIndex].dataset.attr);
        const dataUpdate = _.map(attributes, (item, key) => {
            if (key === idx) {
                item = attr;
            }
            return item;
        })
        setAttributes(dataUpdate);
    }
    const onSubmit = async (data) => {
        let attributeObject = _.uniqBy([...attributes], 'label');
        const attributeData = _.filter(attributeObject, (obj) => {
            if (!obj.value) {
                if ([typeObject.SIMPLE_SELECT, typeObject.MULTI_SELECT].includes(obj.typeData)) {
                    obj.value = obj.selectOption[0].value;
                    return obj;
                } else if ([typeObject.BOOLEAN].includes(obj.typeData)) {
                    obj.value = 'YES';
                    return obj;
                }
            } else {
                return obj;
            }
        });
        let dataProduct = {
            description: data.description,
            meta_description: data.metaDescription,
            meta_keyword: data.metaKeyword,
            meta_title: data.metaTitle,
            sku: data.sku,
            attributes: attributeData
        };
        dataProduct = _.pickBy(dataProduct, v => !_.isNull(v) && !_.isUndefined(v));
        const response = await postProduct(dataProduct);
        if (!_.has(response, 'error')) {
            toast.success('Successfully');
            fetchDataProduct();
            setShow(false);
        } else {
            toast.error(response.errMessage || 'Update failed!');
            setError('sku');
        }
    }
    return (
        <>
            <span title='add'><IconPlus className='cl-pointer' color={'#1d60b0'} title='add' onClick={handleShow}/></span>
            <Modal show={show} onHide={handleClose} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>Add a new product</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <div className='d-table'>
                            <Form.Switch
                                className='mb-3'
                                type='switch'
                                autoFocus
                                defaultChecked={true}
                                name='description'
                                label='Description'
                                {...register("description")}
                            />
                            <Form.Switch
                                className='mb-3' 
                                type='switch'
                                autoFocus
                                defaultChecked={true}
                                name='metaDescription'
                                label='Meta description'
                                {...register("metaDescription")}
                            />
                            <Form.Switch
                                className='mb-3' 
                                type='switch'
                                autoFocus
                                defaultChecked={true}
                                name='metaTitle'
                                label='Meta Title'
                                {...register("metaTitle")}
                            />
                            <Form.Switch
                                className='mb-3' 
                                type='switch'
                                autoFocus
                                defaultChecked={true}
                                name='metaKeyword'
                                label='Meta Keyword'
                                {...register("metaKeyword")}
                            />
                        </div>
                        <hr/>
                        <Form.Group className='mb-3' controlId='sku'>
                            <Form.Label>Sku</Form.Label>
                            <Form.Control
                                type='text'
                                autoFocus
                                name='sku'
                                className={errors.sku ? 'input-error': '' }
                                {...register('sku', { required: true })}
                                aria-invalid={errors.sku ? "true" : "false"} 
                            />
                        </Form.Group>
                        Attributes: 
                        {Array.isArray(attributes) && _.size(attributes) > 0 && attributes.map((val, idx) => {
                            let labelId = `label-${idx}`;
                            let valueId = `value-${idx}`;
                            return <div key={idx} className='form-attributes'>
                                <InputGroup className="mt-2">
                                    <InputGroup.Text id={labelId}>Label & Value<span className='required ms-1'>(*)</span></InputGroup.Text>
                                    <Form.Select
                                        name={labelId}
                                        value={val.id}
                                        onChange={(e) => handleAttribute(e, idx)}
                                    >
                                        {Array.isArray(listA) && listA.map((a, k) =>
                                            <option key={k} value={a.id} data-attr={JSON.stringify(a)}>{a.label}</option>
                                        )}
                                    </Form.Select>
                                    {_.isEqual(val.typeData, typeObject.STRING) &&<Form.Control
                                        name={valueId}
                                        aria-describedby={valueId}
                                        onChange={(e) => handleChange(e)}
                                    />}
                                    {_.isEqual(val.typeData, typeObject.DATE) &&<Form.Control
                                        name={valueId}
                                        aria-describedby={valueId}
                                        type='date'
                                        onChange={(e) => handleChange(e)}
                                    />}
                                    {_.isEqual(val.typeData, typeObject.BOOLEAN) && <Form.Select 
                                        name={valueId}
                                        defaultValue='YES'
                                        onChange={(e) => handleChange(e)}>
                                        <option value='YES'>YES</option>
                                        <option value='NO'>NO</option>
                                    </Form.Select>}
                                    {[typeObject.SIMPLE_SELECT, typeObject.MULTI_SELECT].includes(val.typeData) && <Form.Select
                                        name={valueId}
                                        onChange={(e) => handleChange(e)}>
                                        {Array.isArray(val.selectOption) && val.selectOption.map((o, k) =>
                                            <option key={k} value={o.value}>{o.value}</option>
                                        )}
                                    </Form.Select>}
                                    {_.isEqual(val.typeData, typeObject.NUMBER) && <Form.Control 
                                        type='number'
                                        name={valueId}
                                        defaultValue={0}
                                        onChange={(e) => handleChange(e)}
                                    />}
                                    <InputGroup.Text
                                        className='cl-pointer col-auto'
                                        onClick={(e) => deleteAttribute(e, idx)}>
                                        <IconCircleMinus />
                                    </InputGroup.Text>
                                </InputGroup>
                            </div>
                        })}
                        <span><IconRowInsertBottom className='link-primary mt-2 cl-pointer' size={24} onClick={addAttribute} /></span>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='default' type='button' onClick={handleClose}><IconX />Cancel</Button>
                    <Button variant='primary ms-auto' type='submit' onClick={handleSubmit(onSubmit)}><IconDownload/>Save</Button>
                </Modal.Footer>
            </Modal>
            <Toaster
                position='top-center'
                reverseOrder={false}
            />
        </>
    );
}

export default AddProduct;