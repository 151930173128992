/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import toast, { Toaster } from 'react-hot-toast';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import _ from 'lodash';
import { IconPlus, IconX, IconDownload, IconCalendarTime, IconLetterCase, IconSortAscendingNumbers, IconToggleLeft, IconPointer, IconClick } from '@tabler/icons';
import { postAttribute } from './services';
import { typeObject } from '../../constant';
import AddOptional from './AddOptional';

const AddAttribute = ({ fetchDataAttribute }) => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [show, setShow] = useState(false);
    const [hiddenForm, setHiddenForm] = useState(true);
    const [type, setType] = useState('');
    const [listOptional, setListOptional] = useState([{
        label: '',
        value: '',
        err_label: false,
        err_value: false
    }]);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const createInputType = (type) => {
        setType(type);
        setHiddenForm(false);
        if (type === typeObject.SIMPLE_SELECT || type === typeObject.MULTI_SELECT) {
            setListOptional([{
                label: '',
                value: '',
                err_label: false,
                err_value: false
            }]);
        }
    };

    const validated = () => {
        let isError = false
        if ((type === typeObject.SIMPLE_SELECT || type === typeObject.MULTI_SELECT) && Array.isArray(listOptional) && _.size(listOptional) > 0 ) {
            listOptional.forEach((element, key) => {
                if (element.label.trim() === '') {
                    listOptional[key]['err_label'] = true;
                    isError = true;
                }
                if (element.value.trim() === '') {
                    listOptional[key]['err_value'] = true;
                    isError = true;
                }
            });
        }
        return isError;
    }
    const onSubmit = async (data) => {
        const isError = validated();
        if (isError) {
            return;
        }
        let dataAttribute = {
            label: data.label,
            value: listOptional,
            type: type
        }
        dataAttribute = _.pickBy(dataAttribute, v => !_.isNull(v) && !_.isUndefined(v))
        const response = await postAttribute(dataAttribute);
        if (response) {
            toast.success('Successfully');
            fetchDataAttribute();
            setShow(false);
        }
    }

    const updateOptional = (optional) => {
        setListOptional(optional);
    }

    const InputSSelect = () => (
        <Form.Group className='mb-3' controlId='value'>
            <Form.Label>Optional</Form.Label>
            <AddOptional updateOptional={updateOptional} listOptional={listOptional} />
        </Form.Group>
    )

    const InputMSelect = () => (
        <Form.Group className='mb-3' controlId='value'>
            <Form.Label>Optional</Form.Label>
            <AddOptional updateOptional={updateOptional} listOptional={listOptional} />
        </Form.Group>
    )

    const FormDate = () => (
        <>
            <Form.Group className='mb-3' controlId='label'>
                <Form.Label>Label<span className='required ms-1'>(*)</span></Form.Label>
                <Form.Control
                    type='text'
                    name='label'
                    className={errors.label ? 'input-error': '' }
                    {...register('label', { required: true })}
                    aria-invalid={errors.label ? 'true' : 'false'}
                />
            </Form.Group>
            <Form.Group className='mb-3' controlId='type'>
                <Form.Label>Type</Form.Label>
                <Form.Select id='type'
                    name='type'
                    disabled
                    value={type}>
                    {type === typeObject.DATE && <option value={1}>Date</option>}
                    {type === typeObject.NUMBER && <option value={2}>Number</option>}
                    {type === typeObject.STRING && <option value={3}>Text</option>}
                    {type === typeObject.BOOLEAN && <option value={4}>Yes / No</option>}
                    {type === typeObject.SIMPLE_SELECT && <option value={4}>Simple select</option>}
                    {type === typeObject.MULTI_SELECT && <option value={4}>Multi select</option>}
                </Form.Select>
            </Form.Group>
            {type === typeObject.SIMPLE_SELECT && <InputSSelect />}
            {type === typeObject.MULTI_SELECT && <InputMSelect />}
        </>
    )
    return (
        <>
            <Button variant='primary' onClick={handleShow}>
                <IconPlus/> CREATE ATTRIBUTE
            </Button>
            <Modal show={show} onHide={handleClose} size='xl'>
                <Modal.Header closeButton>
                    <Modal.Title>Choose the attribute type</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='text-center'>
                        <Row>
                            <Col onClick={() => createInputType(typeObject.DATE)} className='cl-pointer'>
                                <IconCalendarTime/>
                                <h3>Date</h3>
                            </Col>
                            <Col onClick={() => createInputType(typeObject.STRING)} className='cl-pointer'>
                                <IconLetterCase/>
                                <h3>Text</h3>
                            </Col>
                            <Col onClick={() => createInputType(typeObject.NUMBER)} className='cl-pointer'>
                                <IconSortAscendingNumbers/>
                                <h3>Number</h3>
                            </Col>
                        </Row>
                        <Row>
                            <Col onClick={() => createInputType(typeObject.BOOLEAN)} className='cl-pointer'>
                                <IconToggleLeft/>
                                <h3>Yes/No</h3>
                            </Col>
                            <Col onClick={() => createInputType(typeObject.SIMPLE_SELECT)} className='cl-pointer'>
                                <IconPointer />
                                <h3>Simple select</h3>
                            </Col>
                            <Col onClick={() => createInputType(typeObject.MULTI_SELECT)} className='cl-pointer'>
                                <IconClick />
                                <h3>Multi select</h3>
                            </Col>
                        </Row>
                    </div>
                    <Form onSubmit={handleSubmit(onSubmit)} hidden={hiddenForm}>
                        <FormDate/>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='default' type='button' onClick={handleClose}><IconX />Cancel</Button>
                    <Button variant='primary ms-auto' type='submit' onClick={handleSubmit(onSubmit)}><IconDownload/>Save</Button>
                </Modal.Footer>
            </Modal>
            <Toaster
                position='top-center'
                reverseOrder={false}
            />
        </>
    );
}

export default AddAttribute;