/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import { useState } from 'react';
import { useForm } from "react-hook-form";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import _ from 'lodash';
import { IconPlus, IconX, IconDownload } from '@tabler/icons';
import { postEntity } from './services';
import { regexSystem } from '../../constant';
const AddEntity = ({ fetchDataEntity }) => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const onSubmit = async (data) => {
        let dataEntity = {
            email: data.email,
            name: data.name,
            website: data.website,
            number_phone: data.numberPhone,
            address: data.address,
            status: data.status,
            zipcode: data.zipcode,
            description: data.description
        }
        dataEntity = _.pickBy(dataEntity, v => !_.isNull(v) && !_.isUndefined(v))
        const response = await postEntity(dataEntity);
        if (response) {
            fetchDataEntity();
            setShow(false);
        }
    }

    return (
        <>
            <Button variant='primary' onClick={handleShow}>
                <IconPlus/> Add Entity
            </Button>

            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Add a new entity</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Email<span className='required ms-1'>(*)</span></Form.Label>
                            <Form.Control
                                type='email'
                                placeholder='email@neotiq.com'
                                autoFocus
                                name='email'
                                className={errors.email ? 'input-error': '' }
                                {...register("email", { required: true, pattern: regexSystem.email.pattern.register})}
                                aria-invalid={errors.email ? "true" : "false"} 
                            />
                        </Form.Group>
                        <Form.Group className='mb-3'>
                            <Form.Label>Company<span className='required ms-1'>(*)</span></Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='Neotiq JSC'
                                autoFocus
                                name='name'
                                className={errors.name ? 'input-error': '' }
                                {...register("name", { required: true })}
                                aria-invalid={errors.name ? "true" : "false"} 
                            />
                        </Form.Group>
                        <Form.Group className='mb-3'>
                            <Form.Label>Website</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='https://www.neotiq.com'
                                autoFocus
                                name='website'
                                className={errors.website ? 'input-error': '' }
                                {...register("website", { required: true, pattern: regexSystem.website.pattern.register })}
                                aria-invalid={errors.website ? "true" : "false"} 
                            />
                        </Form.Group>
                        <Form.Group className='mb-3'>
                            <Form.Label>Address</Form.Label>
                            <Form.Control
                                type='text'
                                autoFocus
                                name='address'
                                className={errors.address ? 'input-error': '' }
                                {...register("address", { required: true, pattern: regexSystem.address.pattern.register })}
                                aria-invalid={errors.address ? "true" : "false"} 
                            />
                        </Form.Group>
                        <Form.Group className='mb-3'>
                            <Form.Label>Phone number</Form.Label>
                            <Form.Control
                                type='text'
                                autoFocus
                                name='numberPhone'
                                className={errors.numberPhone ? 'input-error': '' }
                                {...register("numberPhone", { required: true, pattern: regexSystem.phone.pattern.register })}
                                aria-invalid={errors.numberPhone ? "true" : "false"} 
                            />
                        </Form.Group>
                        <Form.Group className='mb-3'>
                            <Form.Label>ZIP Code</Form.Label>
                            <Form.Control
                                type='text'
                                autoFocus
                                name='zipcode'
                                className={errors.zipcode ? 'input-error': '' }
                                {...register("zipcode", { required: true, pattern: regexSystem.zipCode.pattern.register })}
                                aria-invalid={errors.zipcode ? "true" : "false"} 
                            />
                        </Form.Group>
                        <Form.Switch
                            className='mb-3' 
                            type="switch"
                            autoFocus
                            defaultChecked={true}
                            name='status'
                            label='Status'
                            {...register("status")}
                        />
                        <Form.Group className='mb-3'>
                            <Form.Label>Description</Form.Label>
                            <Form.Control 
                                as='textarea' 
                                rows={3}
                                name='description'
                                {...register("description")}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='default' type='button' onClick={handleClose}><IconX />Cancel</Button>
                    <Button variant='primary ms-auto' type='submit' onClick={handleSubmit(onSubmit)}><IconDownload/>Save</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default AddEntity;