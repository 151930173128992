/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import { useSSE } from 'react-hooks-sse';
import _ from 'lodash';
import { IconArrowUp, IconArrowDown, IconPlayerPause, IconLoader, IconAlertCircle, IconSquareRoundedCheck, IconTableExport } from '@tabler/icons';
import { formatDateString } from '../../utils';
import NoData from '../../components/NoData';
import ConfirmRemove from './ConfirmRemove';
import ShowProduct from './ShowProduct';
import UpdateProduct from './UpdateProduct';

const DataTable = ({ products, paramRequest, fetchDataProduct, pagination, order, sortBy, setProducts, attributes }) => {
    const eventProcessing = useSSE('processing');
    const eventDone = useSSE('done');
    const eventReject = useSSE('reject');
    useEffect(() => {
        if (!_.isUndefined(eventProcessing) || !_.isUndefined(eventDone) || !_.isUndefined(eventReject) ) {
            fetchDataProduct(paramRequest)
        }
    }, [eventProcessing, eventDone, eventReject]);

    const handleChange = (e) => {
        const { name, checked } = e.target;
        if (name === 'allSelect') {
            let tempProd = products.map((prod) => {
                return { ...prod, isChecked: checked };
            });
            setProducts(tempProd);
        } else {
            let tempProd = products.map((prod) =>
                prod.id === Number(name) ? { ...prod, isChecked: checked } : prod
            );
            setProducts(tempProd);
        }
    };

    const statusAI = (status) => {
        if (status === 0) {
            return <IconPlayerPause />
        } else if (status === 1) {
            return <IconLoader />
        } else if (status === 2) {
            return <IconSquareRoundedCheck color={'green'} />
        } else if (status === 3 || status === 4) {
            return <IconAlertCircle color={'danger'} />
        }
    }

    const sortItem = (item) => {
        let itemDom = <IconArrowDown size={12} stroke={1} />;
        if (item === sortBy) {
            if (order === 'ASC') {
                itemDom = <IconArrowUp size={12} stroke={1} />;
            }
        }
        return itemDom;
    }

    return (<>
        <Table className='card-table table-vcenter text-nowrap datatable'>
        <thead>
            <tr>
                <th>
                    <input
                        type='checkbox'
                        className='form-check-input ms-3'
                        name='allSelect'
                        checked={!products.some((prod) => prod?.isChecked !== true)}
                        onChange={handleChange}
                    />
                </th>
                <th>Sku<Link to={'#'} onClick={() => fetchDataProduct({ sortBy: 'sku' })}>{sortItem('sku')}</Link></th>
                <th>Status</th>
                <th>Creation<Link to={'#'} onClick={() => fetchDataProduct({ sortBy: 'createdAt' })}>{sortItem('createdAt')}</Link></th>
                <th>Updation<Link to={'#'} onClick={() => fetchDataProduct({ sortBy: 'updatedAt' })}>{sortItem('updatedAt')}</Link></th>
                <th>Action</th>
            </tr>
        </thead>
        <tbody>
            {Array.isArray(products) && _.size(products) > 0 ? products.map((row, i) => {
            const tmp = row.productTmp;
            return <tr key={row.id}>
                <td><span className='text-muted'>{pagination.offset + i + 1} </span>
                    <input
                        type='checkbox'
                        className='form-check-input ms-1'
                        name={row.id}
                        checked={row?.isChecked || false}
                        onChange={handleChange}
                    />
                </td>
                <td>{row.sku}</td>
                <td>{statusAI((Array.isArray(tmp) && _.size(tmp)) ? tmp.filter(t => t.srp_id === row.srp_id.id)[0].status: 0)}</td>
                <td>{formatDateString(row.createdAt)}</td>
                <td>{formatDateString(row.updatedAt)}</td>
                <td>
                    <ShowProduct data={row} />
                    <UpdateProduct data={row} fetchDataProduct={fetchDataProduct} listA={attributes} />
                    <ConfirmRemove
                        title='Are you sure?'
                        message='Do you really want to remove record.'
                        id={row.id}
                        fetchDataProduct={fetchDataProduct}
                    />
                </td>
            </tr>
        }) : <NoData />}
        </tbody>
        </Table>
    </>)
}

export default DataTable;