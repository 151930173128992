/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Form, Row } from 'react-bootstrap';
import { SSEProvider } from 'react-hooks-sse';
import _ from 'lodash';
import moment from 'moment';
import * as XLSX from 'xlsx';
import FileSaver from 'file-saver'
import { IconSearch, IconX, IconTableExport } from '@tabler/icons';
import { getProducts, exportProducts } from './services';
import { getAttributes } from '../Attributes/services';
import * as Storage from '../../storage';
import { ObjectToQueryString } from '../../utils';
import Header from '../../components/Header';
import Aside from '../../components/Aside';
import Footer from '../../components/Footer';
import AddProduct from './AddProduct';
import DataTable from './DataTable';
import Paging from './Paging'
import ImportProduct from './ImportProduct';
import { API_ROOT } from '../../config';

const BASE_URL = API_ROOT;
const eventUrl = `${BASE_URL}/ai-product/event`;
const LIMIT = 20;
const PAGE_DISPLAY = 5;

const ProductPage = (props) => {
    const jwtToken = JSON.parse(localStorage.getItem(Storage.JWT_TOKEN))
    const userInfo = JSON.parse(localStorage.getItem(Storage.USER_INFO));
    const [products, setProducts] = useState([]);
    const [attributes, setAttributes] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [pagination, setPagination] = useState({ limit: LIMIT, offset: 0, totalCount: 20 });
    const [pageActive, setPageActive] = useState(1);
    const [order, setOrder] = useState('ASC');
    const [sortBy, setSortBy] = useState('createdAt');
    const [paramRequest, setParamRequest] = useState({});
    // const [isLoading, setIsLoading] = useState(false);
   
    // Init data entity
    let isInit = true;
    useEffect(() => {
        if (isInit) {
            isInit = false
            fetchDataProduct();
            fetchDataAttribute();
        }
    }, []);
    const fetchDataAttribute = async() => {
        const params = {
            limit: -1
        }
        const response = await getAttributes(params);
        if (response && _.has(response, 'data')) {
            setAttributes(response.data);
        }
        return;
    }
    const fetchDataProduct = async (params = {}) => {
        const pageA = params.pageActive || 1
        setPageActive(pageA);
        params.order = order;
        params.limit = LIMIT;
        params.offset = (pageA - 1) * LIMIT;
        if (params.name === '') {
            delete params.name;
        } else {
            params.name = searchText;
        }
        if (params.sortBy) {
            setSortBy(params.sortBy);
            if (params.order === 'ASC') {
                setOrder('DESC');
            } else {
                setOrder('ASC');
            }
        }
        const response = await getProducts(params);
        if (response) {
            setParamRequest(params);
            if (_.has(response, 'data')) {
                setProducts(response.data);
            }
            if (_.has(response, 'pagination')) {
                setPagination(response.pagination);
            }
        }
        return;
    }
    
    const handleSearch = (event) => {
        event.preventDefault();
        fetchDataProduct({ name: searchText });
    }
    const handleReset = () => {
        setSearchText('');
        fetchDataProduct({ name: '' });
    }
    const handleExport = async () => {
        // setIsLoading(true);
        const params = {
            name: searchText
        };
        if(searchText === '') {
            delete params.name;
        }
        const resultProducts = await exportProducts(params);
        if (resultProducts) {
            const dateTime = moment().format('DD-MM-YYYY');
            const headers = [
                { title: 'Sku', dataKey: 'sku' },
                { title: 'Description', dataKey: 'description' },
                { title: 'Meta Description', dataKey: 'metaDescription' },
                { title: 'Meta title', dataKey: 'metaTitle' },
                { title: 'Meta Keywords', dataKey: 'keyword' }
            ];
            let listDataExport = [];
            if (Array.isArray(resultProducts.data) && _.size(resultProducts.data) > 0) {
                resultProducts.data.map((item) => {
                    const content = JSON.parse(item.content);
                    return listDataExport.push({
                        sku: item.sku,
                        description: !_.isNull(content) ? content.description : '',
                        metaDescription: !_.isNull(content) ? content.metaDescription : '',
                        metaTitle: !_.isNull(content) ? content.metaTitle : '',
                        keyword: !_.isNull(content) ? content.keyword : ''
                    });
                });

                const ws = XLSX.utils.book_new()
                XLSX.utils.sheet_add_aoa(ws, [_.map(headers, 'title')])
                XLSX.utils.sheet_add_json(ws, listDataExport, { origin: 'A2', skipHeader: true })
                const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] }
                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array', cellStyles: true })
                const finalData = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' })
                FileSaver.saveAs(finalData, 'export-products-' + dateTime + '.xlsx')
            }
        }
        // setIsLoading(false);
    }

    return (<>
        <Aside match={props.match} />
        <Header userInfo={userInfo} />
        <div className='page-wrapper mt-55'>
            <div className='page-body'>
                <div className='row row-deck row-cards'>
                    <div className='col-12'>
                        <div className='card'>
                            <div className='card-header'>
                                <h3 className='card-title col'>List of products</h3>
                                <div className='col-auto'>
                                    <ImportProduct fetchDataProduct={fetchDataProduct} />
                                    <span title='export CSV'><IconTableExport className='cl-pointer' color={'#1d60b0'} onClick={handleExport} /></span>
                                </div>
                            </div>
                            <div className='card-body border-bottom py-3'>
                                <Row>
                                    <div className='col'>
                                        <Form onSubmit={handleSearch} className='ms-auto text-muted d-inline-block'>
                                            <div className='input-group input-group-flat'>
                                                <input type='text' name='search' className='form-control' placeholder='Search product…' onChange={(e) => setSearchText(e.target.value)} value={searchText} />
                                                <span className='input-group-text'>
                                                    {!_.isEmpty(searchText) && <Link className='link-secondary' to={'#'} title='Clear search' data-bs-toggle='tooltip' onClick={handleReset}><IconX /></Link>}
                                                    <Link className='link-secondary' to={'#'} onClick={handleSearch}><IconSearch /></Link>
                                                </span>
                                            </div>
                                        </Form>
                                    </div>
                                    <div className='col-auto'>
                                        <AddProduct fetchDataProduct={fetchDataProduct} listA={attributes} />
                                    </div>
                                </Row>
                            </div>
                            <div className='table-responsive' style={{ minHeight: '300px' }}>
                                <SSEProvider endpoint={`${eventUrl}?${ObjectToQueryString({ token: jwtToken })}`}>
                                    <DataTable userInfo={userInfo} 
                                        setProducts={setProducts}
                                        products={products} 
                                        paramRequest={paramRequest} 
                                        fetchDataProduct={fetchDataProduct} 
                                        pagination={pagination} 
                                        order={order}
                                        sortBy={sortBy}
                                        attributes={attributes} />
                                </SSEProvider>
                            </div>
                            <div className='card-footer d-flex align-items-center'>
                                {!_.isUndefined(pagination) &&
                                    <Paging
                                        activePage={pageActive}
                                        itemsCountPerPage={pagination.limit || LIMIT}
                                        totalItemsCount={pagination.totalCount}
                                        pageRangeDisplayed={PAGE_DISPLAY}
                                        requestList={fetchDataProduct}
                                    />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    </>);
};

export default ProductPage;