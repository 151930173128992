/* eslint-disable react/prop-types */
import React, { useEffect, useState, useLayoutEffect } from 'react';
import { Link } from 'react-router-dom';
import { Table, Form } from 'react-bootstrap';
import _ from 'lodash';
import moment from 'moment';
import * as XLSX from 'xlsx';
import FileSaver from 'file-saver'
import { IconArrowUp, IconArrowDown, IconSearch, IconX, IconSquarePlus, IconFileExport } from '@tabler/icons';
import { formatDateString } from '../../utils';
import { getProducts, exportProducts } from './services';
import * as Storage from '../../storage';
import Header from '../../components/Header';
import Aside from '../../components/Aside';
import Footer from '../../components/Footer';
import NoData from '../../components/NoData';
import { ButtonClipLoader } from '../../components/Loading';
import ConfirmRemove from './ConfirmRemove';
import ShowProduct from './ShowProduct';
import Paging from './Paging'
import ImportProduct from './ImportProduct';

const LIMIT = 20;
const PAGE_DISPLAY = 5;

const ProductMaualPage = (props) => {
    const userInfo = JSON.parse(localStorage.getItem(Storage.USER_INFO));
    const [products, setProducts] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [pagination, setPagination] = useState({ limit: LIMIT, offset: 0, totalCount: 20 });
    const [pageActive, setPageActive] = useState(1);
    const [order, setOrder] = useState('ASC');
    const [sortBy, setSortBy] = useState('createdAt');
    const [isLoading, setIsLoading] = useState(false);
    // Init data products
    let isLoaded = false
    useEffect(() => {
        if (!isLoaded) {
            isLoaded = true
            fetchDataProduct();
        }
    }, []);

    // DidUpdate reset action
    useLayoutEffect(() => {
        // Action reset
    });

    const fetchDataProduct = async (params = {}) => {
        const pageA = params.pageActive || 1
        setPageActive(pageA);
        params.order = order;
        params.limit = LIMIT;
        params.offset = (pageA - 1) * LIMIT;
        if (params.name === '') {
            delete params.name;
        } else {
            params.name = searchText;
        }
        if (params.sortBy) {
            setSortBy(params.sortBy);
            if (params.order === 'ASC') {
                setOrder('DESC');
            } else {
                setOrder('ASC');
            }
        }
        const response = await getProducts(params);
        if (response) {
            if (_.has(response, 'data')) {
                setProducts(response.data);
            }
            if (_.has(response, 'pagination')) {
                setPagination(response.pagination);
            }
        }
        return;
    }
    const sortItem = (item) => {
        let itemDom = <IconArrowDown size={12} stroke={1} />;
        if (item === sortBy) {
            if (order === 'ASC') {
                itemDom = <IconArrowUp size={12} stroke={1} />;
            }
        }
        return itemDom;
    }
    const handleSearch = (event) => {
        event.preventDefault();
        fetchDataProduct({ name: searchText });
    }
    const handleReset = () => {
        setSearchText('');
        fetchDataProduct({ name: '' });
    }
    const handleExport = async () => {
        setIsLoading(true);
        const params = {
            name: searchText
        };
        if(searchText === '') {
            delete params.name;
        }
        const resultProducts = await exportProducts(params);
        if (resultProducts) {
            const dateTime = moment().format('DD-MM-YYYY');
            const headers = [
                { title: 'Sku', dataKey: 'name' },
                { title: 'Description', dataKey: 'description' },
                { title: 'Meta Description', dataKey: 'metaDescription' },
                { title: 'Meta title', dataKey: 'metaTitle' },
                { title: 'Meta Keywords', dataKey: 'keyword' }
            ];
            let listDataExport = [];
            if (Array.isArray(resultProducts.data) && _.size(resultProducts.data) > 0) {
                resultProducts.data.map((item) => {
                    const content = JSON.parse(item.content);
                    return listDataExport.push({
                        name: item.name,
                        description: content.description || '',
                        metaDescription: content.metaDescription || '',
                        metaTitle: content.metaTitle || '',
                        keyword: content.keyword || ''
                    });
                })

                const ws = XLSX.utils.book_new()
                XLSX.utils.sheet_add_aoa(ws, [_.map(headers, 'title')])
                XLSX.utils.sheet_add_json(ws, listDataExport, { origin: 'A2', skipHeader: true })
                const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] }
                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array', cellStyles: true })
                const finalData = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' })
                FileSaver.saveAs(finalData, 'listDataExport_' + dateTime + '.xlsx')
            }
        }
        setIsLoading(false);
    }

    const handleChange = (e) => {
        const { name, checked } = e.target;
        if (name === "allSelect") {
            let tempProd = products.map((prod) => {
                return { ...prod, isChecked: checked };
            });
            setProducts(tempProd);
        } else {
            let tempProd = products.map((prod) =>
                prod.id === Number(name) ? { ...prod, isChecked: checked } : prod
            );
            setProducts(tempProd);
        }
    };

    return (<>
        <Aside match={props.match} />
        <Header userInfo={userInfo} />
        <div className='page-wrapper mt-55'>
            <div className='page-body'>
                <div className='row row-deck row-cards'>
                    <div className='col-12'>
                        <div className='card'>
                            <div className='card-header'>
                                <h3 className='card-title col'>List of products</h3>
                                <div className='col-auto'>
                                    <ImportProduct />
                                    <ButtonClipLoader variant='primary me-2' type='button' isLoading={isLoading} icon={<IconFileExport />} onClick={handleExport} label={'Export CSV'} />
                                    <Link className='btn' to={'/products-manual/add'}><IconSquarePlus />Add product</Link>
                                </div>
                            </div>
                            <div className='card-body border-bottom py-3'>
                                <div className='d-flex'>
                                    <Form onSubmit={handleSearch} className='ms-auto text-muted d-inline-block'>
                                        <div className='input-group input-group-flat'>
                                            <input type='text' name='search' className='form-control' placeholder='Search product…' onChange={(e) => setSearchText(e.target.value)} value={searchText} />
                                            <span className='input-group-text'>
                                                {!_.isEmpty(searchText) && <Link className='link-secondary' to={'#'} title='Clear search' data-bs-toggle='tooltip' onClick={handleReset}><IconX /></Link>}
                                                <Link className='link-secondary' to={'#'} onClick={handleSearch}><IconSearch /></Link>
                                            </span>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                            <div className='table-responsive' style={{ minHeight: '300px' }}>
                                <Table className='card-table table-vcenter text-nowrap datatable'>
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input'
                                                    name='allSelect'
                                                    checked={!products.some((prod) => prod?.isChecked !== true)}
                                                    onChange={handleChange}
                                                />
                                            </th>
                                            <th>Sku<Link to={'#'} onClick={() => fetchDataProduct({ sortBy: 'name' })}>{sortItem('name')}</Link></th>
                                            <th>Creation date<Link to={'#'} onClick={() => fetchDataProduct({ sortBy: 'createdAt' })}>{sortItem('createdAt')}</Link></th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Array.isArray(products) && _.size(products) > 0 ? products.map((row, i) =>
                                            <tr key={row.id}>
                                            <td><span className='text-muted'>{i + 1}</span></td>
                                            <td>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input'
                                                    name={row.id}
                                                    checked={row?.isChecked || false}
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{row.name}</td>
                                            <td>{formatDateString(row.createdAt)}</td>
                                            <td>
                                                <ShowProduct data={row} />
                                                <ConfirmRemove
                                                    title='Are you sure?'
                                                    message='Do you really want to remove record.'
                                                    id={row.id}
                                                    fetchDataProduct={fetchDataProduct}
                                                />
                                            </td>
                                        </tr>
                                        ) : <NoData />}
                                    </tbody>
                                </Table>
                            </div>
                            <div className='card-footer d-flex align-items-center'>
                                {!_.isUndefined(pagination) &&
                                    <Paging
                                        activePage={pageActive}
                                        itemsCountPerPage={pagination.limit || LIMIT}
                                        totalItemsCount={pagination.totalCount}
                                        pageRangeDisplayed={PAGE_DISPLAY}
                                        requestList={fetchDataProduct}
                                    />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    </>);
};

export default ProductMaualPage;