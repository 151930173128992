import fetch from 'isomorphic-fetch';
import * as Utils from '../../utils';
import { API_ROOT } from '../../config';

const BASE_URL = API_ROOT;

export const getEntities = async (params) => {
    const response = await fetch(`${BASE_URL}/entities?${Utils.ObjectToQueryString(params)}`, {
        method: 'GET',
        headers: Utils.commonHeaders(),
    });
    return Utils.handleNon200s(response);
}

export const getEntity = async (params) => {
    const response = await fetch(`${BASE_URL}/entities/${params.id}`, {
        method: 'GET',
        headers: Utils.commonHeaders(),
    });
    return Utils.handleNon200s(response);
}

export const putEntity = async (data) => {
    try {
        const response = await fetch(`${BASE_URL}/entities/${data.id}`, {
            method: 'PUT',
            headers: Utils.commonHeaders(),
            body: JSON.stringify(data)
        });
        return Utils.handleNon200s(response);
    } catch (error) {
        console.log('error: ', error);
        return false;
    }
};

export const putEntityStatus = async (data) => {
    try {
        const response = await fetch(`${BASE_URL}/entities/status/${data.id}`, {
            method: 'PUT',
            headers: Utils.commonHeaders(),
            body: JSON.stringify(data)
        });
        return Utils.handleNon200s(response);
    } catch (error) {
        console.log('error: ', error);
        return false;
    }
};

export const postEntity = async (data) => {
    try {
        const response = await fetch(`${BASE_URL}/entities`, {
            method: 'POST',
            headers: Utils.commonHeaders(),
            body: JSON.stringify(data)
        });
        return Utils.handleNon200s(response);
    } catch (error) {
        console.log('error: ', error);
        return false;
    }
};

export const deleteEntity = async (params) => {
    const response = await fetch(`${BASE_URL}/entities/${params.id}`, {
        method: 'DELETE',
        headers: Utils.commonHeaders(),
    });
    return Utils.handleNon200s(response);
}