/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Table, Form } from 'react-bootstrap';
import _ from 'lodash';
import {
    IconArrowUp, IconArrowDown, IconSearch, IconX, IconSquareRoundedLetterA,
    IconCalendarTime, IconLetterCase, IconSortAscendingNumbers,
    IconToggleLeft, IconPointer, IconClick } from '@tabler/icons';
import * as Storage from '../../storage';
import { typeObject } from '../../constant';
import Header from '../../components/Header';
import Aside from '../../components/Aside';
import Footer from '../../components/Footer';
import NoData from '../../components/NoData';
import AddAttribute from './AddAttribute';
import ImportAttribute from './ImportAttribute';
import UpdateAttribute from './modalUpdate';
import ConfirmRemove from './ConfirmRemove';
import Paging from './Paging';
import { getAttributes } from './services';

const LIMIT = 20;
const PAGE_DISPLAY = 5;
const AttributesPage = (props) => {
    const userInfo = JSON.parse(localStorage.getItem(Storage.USER_INFO));
    const [attributes, setAttributes] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [pagination, setPagination] = useState({ limit: LIMIT, offset: 0, totalCount: 20 });
    const [order, setOrder] = useState('ASC');
    const [sortBy, setSortBy] = useState('createdAt');
    // Init data attribute
    let isInit = true;
    useEffect(() => {
        if (isInit) {
            isInit = false
            fetchDataAttribute();
        }
    }, []);

    const fetchDataAttribute = async (params = {}) => {
        const pageActive = params.pageActive || 1;
        params.order = order;
        params.limit = LIMIT;
        params.offset = (pageActive - 1) * LIMIT;
        if (params.label === '') {
            delete params.label;
        } else {
            params.label = searchText;
        }
        if (params.sortBy) {
            setSortBy(params.sortBy);
            if (params.order === 'ASC') {
                setOrder('DESC');
            } else {
                setOrder('ASC');
            }
        }
        const response = await getAttributes(params);
        if (response) {
            if (_.has(response, 'data')) {
                setAttributes(response.data);
            }
            if (_.has(response, 'pagination')) {
                setPagination(response.pagination);
            }
        }
        return;
    }

    const ItemSort = (item) => {
        let itemDom = <IconArrowDown size={12} stroke={1} />;
        if (item === sortBy) {
            if (order === 'ASC') {
                itemDom = <IconArrowUp size={12} stroke={1} />;
            }
        }
        return itemDom;
    }
    const handleSearch = (event) => {
        event.preventDefault();
        fetchDataAttribute({ label: searchText });
    }
    const handleReset = () => {
        setSearchText('');
        fetchDataAttribute({ label: '' });
    }
    return (<>
        <Aside match={props.match} />
        <Header userInfo={userInfo} />
        <div className='page-wrapper mt-55'>
            <div className='page-body'>
                <div className='row row-deck row-cards'>
                    <div className='col-12'>
                        <div className='card'>
                            <div className='card-header'>
                                <h3 className='card-title col'><IconSquareRoundedLetterA stroke={1} width={45} height={45} /> List of attributes</h3>
                                <div className='col-auto'>
                                    <ImportAttribute fetchDataAttribute={fetchDataAttribute} />
                                    <AddAttribute fetchDataAttribute ={fetchDataAttribute } />
                                </div>
                            </div>
                            <div className='card-body border-bottom py-3'>
                                <div className='d-flex'>
                                    <Form onSubmit={handleSearch} className='ms-auto text-muted d-inline-block'>
                                        <div className='input-group input-group-flat'>
                                            <input type='text' name='search' className='form-control' placeholder='Search attribute..' onChange={(e) => setSearchText(e.target.value)} value={searchText} />
                                            <span className='input-group-text'>
                                                {!_.isEmpty(searchText) && <Link className='link-secondary' to={'#'} title='Clear search' data-bs-toggle='tooltip' onClick={handleReset}><IconX /></Link>}
                                                <Link className='link-secondary' to={'#'} onClick={handleSearch}><IconSearch /></Link>
                                            </span>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                            <div className='table-responsive' style={{ minHeight: '300px' }}>
                                <Table className='card-table table-vcenter text-nowrap datatable'>
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>Label<Link to={'#'} onClick={() => fetchDataAttribute({ sortBy: 'label' })}>{ItemSort('Label')}</Link></th>
                                            <th>Type<Link to={'#'} onClick={() => fetchDataAttribute({ sortBy: 'typeData' })}>{ItemSort('Type')}</Link></th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Array.isArray(attributes) && _.size(attributes) > 0 ? attributes.map((row, i) =>
                                            <tr key={row.id}>
                                                <td><span className='text-muted'>{pagination.offset + i + 1}</span></td>
                                                <td>{row.label && row.label}</td>
                                                <td>
                                                    {row.typeData === typeObject.DATE && <><IconCalendarTime/> Date</>}
                                                    {row.typeData === typeObject.NUMBER && <><IconSortAscendingNumbers/> Number</>}
                                                    {row.typeData === typeObject.STRING && <><IconLetterCase/> Text</>} 
                                                    {row.typeData === typeObject.BOOLEAN && <><IconToggleLeft/> Yes / No</>}
                                                    {row.typeData === typeObject.SIMPLE_SELECT && <><IconPointer/> Simple select</>}
                                                    {row.typeData === typeObject.MULTI_SELECT && <><IconClick/> Multi select</>}
                                                </td>
                                                <td>
                                                    <UpdateAttribute attribute={row} fetchDataAttribute={fetchDataAttribute} />
                                                    <ConfirmRemove
                                                        title='Confirm deletion'
                                                        message='Are you sure you want to delete this item?'
                                                        id={row.id}
                                                        fetchDataAttribute={fetchDataAttribute}
                                                    />
                                                </td>
                                            </tr>
                                        ) : <NoData />}
                                    </tbody>
                                </Table>
                            </div>
                            <div className='card-footer d-flex align-items-center'>
                                {!_.isUndefined(pagination) &&
                                <Paging
                                    activePage={pagination.offset / LIMIT + 1}
                                    itemsCountPerPage={pagination.limit || LIMIT}
                                    totalItemsCount={pagination.totalCount}
                                    pageRangeDisplayed={PAGE_DISPLAY}
                                    requestList={fetchDataAttribute}
                                />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </>)
}

export default AttributesPage;