/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import toast, { Toaster } from 'react-hot-toast';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import _ from 'lodash';
import { IconEditCircle, IconX, IconDownload, IconCalendarTime, IconLetterCase, IconSortAscendingNumbers, IconToggleLeft, IconPointer, IconClick } from '@tabler/icons';
import { putAttribute } from './services';
import { typeObject } from '../../constant';
import AddOptional from './AddOptional';
const UpdateAttribute = ({ attribute, fetchDataAttribute }) => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [listOptional, setListOptional] = useState(attribute.selectOption);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    useEffect(() => {
        setListOptional(attribute.selectOption)
    }, [attribute]);
    const onSubmit = async (data) => {
        let dataAttribute = {
            id: attribute.id,
            type: attribute.typeData,
            label: data.label,
            selectOption: listOptional
        }
        dataAttribute = _.pickBy(dataAttribute, v => !_.isNull(v) && !_.isUndefined(v));
        const response = await putAttribute(dataAttribute);
        if (response) {
            toast.success('Successfully');
            fetchDataAttribute();
            setShow(false);
        }
    }

    const updateOptional = (optional) => {
        setListOptional(optional);
    }

    const InputSSelect = () => (
        <Form.Group className='mb-3' controlId='value'>
            <Form.Label>Optional</Form.Label>
            <AddOptional updateOptional={updateOptional} listOptional={listOptional} />
        </Form.Group>
    )

    const InputMSelect = () => (
        <Form.Group className='mb-3' controlId='value'>
            <Form.Label>Optional</Form.Label>
            <AddOptional updateOptional={updateOptional} listOptional={listOptional} />
        </Form.Group>
    )
    return (
        <>
            <span className='text-primary me-2 cl-pointer' onClick={handleShow}><IconEditCircle /></span>
            <Modal show={show} onHide={handleClose} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>Update Attribute</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Group className='mb-3' controlId='label'>
                            <Form.Label>Label<span className='required ms-1'>(*)</span></Form.Label>
                            <Form.Control
                                type='text'
                                defaultValue={attribute.label}
                                name='label'
                                className={errors.label ? 'input-error': '' }
                                {...register("label")}
                                aria-invalid={errors.label ? "true" : "false"} 
                            />
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='type'>
                            <Form.Label><span className='me-2'>Type: </span> 
                                {attribute.typeData === typeObject.DATE && <IconCalendarTime />}
                                {attribute.typeData === typeObject.NUMBER && <IconSortAscendingNumbers/>}
                                {attribute.typeData === typeObject.STRING && <IconLetterCase/>}
                                {attribute.typeData === typeObject.BOOLEAN && <IconToggleLeft/>}
                                {attribute.typeData === typeObject.SIMPLE_SELECT && <IconPointer/>}
                                {attribute.typeData === typeObject.MULTI_SELECT && <IconClick/>}
                            </Form.Label>
                            <Form.Select id='type'
                                name='type'
                                disabled
                                defaultValue={attribute.typeData}>
                                {attribute.typeData === typeObject.DATE && <option value={1}>Date</option>}
                                {attribute.typeData === typeObject.NUMBER && <option value={2}>Number</option>}
                                {attribute.typeData === typeObject.STRING && <option value={3}>Text</option>}
                                {attribute.typeData === typeObject.BOOLEAN && <option value={4}>Yes / No</option>}
                                {attribute.typeData === typeObject.SIMPLE_SELECT && <option value={4}>Simple select</option>}
                                {attribute.typeData === typeObject.MULTI_SELECT && <option value={4}>Multi select</option>}
                            </Form.Select>
                        </Form.Group>
                        {attribute.typeData === typeObject.SIMPLE_SELECT && <InputSSelect />}
                        {attribute.typeData === typeObject.MULTI_SELECT && <InputMSelect />}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='default' type='button' onClick={handleClose}><IconX />Cancel</Button>
                    <Button variant='primary ms-auto' type='submit' onClick={handleSubmit(onSubmit)}><IconDownload />Save</Button>
                </Modal.Footer>
            </Modal>
            <Toaster
                position='top-center'
                reverseOrder={false}
            />
        </>
    );
}

export default UpdateAttribute;