import React, { Component } from 'react'
import { Table, Modal, Form, Button } from 'react-bootstrap'
import { IconCloudUpload, IconRefresh } from '@tabler/icons'
import { CSVReader } from 'react-papaparse'
import _ from 'lodash'
import * as Types from './actions'
// import * as TypeAttributes from '../Attributes/actions'

const buttonRef = React.createRef()

class ModalImport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        }
        // this.getListAttribute();
    }

    // getListAttribute = () => {
    //     const QUERY_LIST = {
    //         sortBy: 'createdAt',
    //         order: 'DESC',
    //         limit: 100,
    //         offset: 0
    //     }
    //     this.props.dispatch({ type: TypeAttributes.ATTRIBUTE_LIST.REQUESTED, detail: QUERY_LIST })
    // }


    static getDerivedStateFromProps(props, state) {
        if (props.importSuccess) {
            state.isOpen = false
            state.isLoading = false
        }
        return state;
    }

    toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen,
            fileName: "",
            column: [],
            formData: [],
            csvFile: {},
            matching: [],
            isLoading: false,
            isError: false,
            errors: []
        })
    }

    handleSubmit = (event) => {
        event.preventDefault();
        const { fileName, formData, matching } = this.state;
        if (matching.length > 0) {
            for (let index = 0; index < matching.length; index++) {
                const element = event.target.getElementsByTagName("select")["attribute_" + matching[index].key];
                const selectedIndex = element[element.selectedIndex];
                const aId = selectedIndex.dataset.id;
                matching[index].id = aId;
            }
        }
        const csv = {
            fileName, formData, matching
        };
        this.props.dispatch({ type: Types.CSV_CREATE.REQUESTED, detail: csv })
        this.toggle()
    }

    handleOpenDialog = (e) => {
        // Note that the ref is set async, so it might be null at some point
        if (buttonRef.current) {
            buttonRef.current.open(e)
        }
    }

    handleOnFileLoad = async (data, csvFile) => {
        let { errors } = this.state
        const { name } = csvFile
        const pattern = "^.+\\.(csv)$"
        const regex = new RegExp(pattern);
        if (regex.test(name)) {
            this.setState({
                column: data[0].data,
                errors: [],
                fileName: name,
                formData: data
            })
        } else {
            errors.push("Import has been accept only csv file");
            this.setState({ errors })
        }
    }

    handleOnError = (err, file, inputElem, reason) => {
        console.log(err)
    }

    handleOnRemoveFile = (data) => {
        this.setState({ errors: [], formData: data, csvFile: {} })
    }

    handleRemoveFile = (e) => {
        // Note that the ref is set async, so it might be null at some point
        if (buttonRef.current) {
            buttonRef.current.removeFile(e)
        }
    }

    handChangeCheckbox = (event) => {
        const inputChecked = event.target.checked;
        const inputKey = event.target.dataset.key;
        const column = event.target.dataset.column;
        const { matching } = this.state;
        const i = matching.findIndex(_item => _item.key === inputKey);
        if (inputChecked) {
            if (i === -1) matching.push({
                key: inputKey,
                column: column
            });
        } else {
            if (i > -1) matching.splice(i, 1);
        }
    }

    render() {
        const { column } = this.state;
        const { attributes } = this.props
        return (
            <React.Fragment>
                <Button className="btn btn-primary" onClick={this.toggle}>
                    <IconCloudUpload />Import File CSV
                </Button>
                <Modal show={this.state.isOpen} onHide={this.toggle} className="modal-blur" centered size="lg" >
                    <Form onSubmit={this.handleSubmit}>
                        <Modal.Header closeButton>
                            <Modal.Title>Import File CSV</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="mb-3 row">
                                <CSVReader
                                    accept={'.csv, text/csv'}
                                    ref={buttonRef}
                                    onFileLoad={this.handleOnFileLoad}
                                    onError={this.handleOnError}
                                    noClick
                                    noDrag
                                    onRemoveFile={this.handleOnRemoveFile}
                                >
                                    {({ file }) => (
                                        <aside style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
                                            <Button
                                                type='button'
                                                className="btn-outline-primary"
                                                onClick={this.handleOpenDialog}
                                                style={{ borderRadius: 0, width: '40%' }}>
                                                <IconCloudUpload /> Browse file .csv
                                            </Button>
                                            <div style={{
                                                borderWidth: 1,
                                                borderStyle: 'solid',
                                                borderColor: '#ccc',
                                                lineHeight: 2.5,
                                                paddingLeft: '10px',
                                                width: '60%'
                                            }}>
                                                {file && file.name}
                                            </div>
                                            <Button
                                                type="button"
                                                className="btn-outline-secondary"
                                                style={{ borderRadius: 0 }}
                                                onClick={this.handleRemoveFile}><IconRefresh style={{ margin: '0px' }} /></Button>
                                        </aside>
                                    )}
                                </CSVReader>
                            </div>
                            {Array.isArray(column) && column.length > 0 &&
                                <div className="mb-3 row">
                                    <label className="form-label">Map fields</label>
                                    <p>Select the CSV fields to import then set how you would like these converted to fields in Poolp</p>
                                    <Table className="card-table table-vcenter text-nowrap datatable">
                                        <thead>
                                            <tr>
                                                <th>CSV Field</th>
                                                <th>Attribute</th>
                                                <th>Map field value</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {column.map((row, i) =>
                                                <tr key={i}>
                                                    <td>{row}</td>
                                                    <td>
                                                        <select className="form-select" name={"attribute_" + i} defaultValue={_.snakeCase(row)}>
                                                            {Array.isArray(attributes) && attributes.map((a, k) =>
                                                                <option key={k} value={a.value} data-id={a.id}>{a.label}</option>
                                                            )}
                                                        </select>
                                                    </td>
                                                    <td className="w-1"><input className="form-check-input" name={"field_" + i} data-column={_.snakeCase(row)} data-key={i} type="checkbox" onChange={this.handChangeCheckbox}/></td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                </div>}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="link-secondary" onClick={this.toggle}>Cancel</Button>
                            <Button variant="primary ms-auto" type="submit"><IconCloudUpload />Import</Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default ModalImport;

