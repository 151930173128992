/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import _ from 'lodash';
import { IconX, IconDownload, IconEyeOff, IconEye } from '@tabler/icons';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import { changePasswordProfile } from './services';
import { regexSystem } from '../../constant';

const ModalPassword = () => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [eyeCurrent, setEyeCurrent] = useState(true);
    const [eyePassword, setEyePassword] = useState(true);
    const [eyeConfirm, setEyeConfirm] = useState(true);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    const onSubmit = async (data) => {
        const dataUser = {
            currentPassword: data.currentPassword,
            confirmPassword: data.confirmPassword,
            newPassword: data.newPassword
        }
        const response = await changePasswordProfile(dataUser);
        if (response) {
            setShow(false);
        }
    }
    const EyeElement = (label) => {
        let domShow = '';
        if (label === 'current') {
            if (!eyeCurrent) {
                domShow = <span className="link-secondary" title="Hidden password" data-bs-toggle="tooltip" onClick={() => setEyeCurrent(true)}><IconEye /></span>;    
            } else {
                domShow = <span className="link-secondary" title="Show password" data-bs-toggle="tooltip" onClick={() => setEyeCurrent(false)}><IconEyeOff /></span>;
            }
        }
        if (label === 'password') {
            if (!eyePassword) {
                domShow = <span className="link-secondary" title="Hidden password" data-bs-toggle="tooltip" onClick={() => setEyePassword(true)}><IconEye /></span>;    
            } else {
                domShow = <span className="link-secondary" title="Show password" data-bs-toggle="tooltip" onClick={() => setEyePassword(false)}><IconEyeOff /></span>;
            }
        }
        if (label === 'confirm') {
            if (!eyeConfirm) {
                domShow = <span className="link-secondary" title="Hidden password" data-bs-toggle="tooltip" onClick={() => setEyeConfirm(true)}><IconEye /></span>;    
            } else {
                domShow = <span className="link-secondary" title="Show password" data-bs-toggle="tooltip" onClick={() => setEyeConfirm(false)}><IconEyeOff /></span>;
            }
        }
        return domShow;
    }
    return (<>
        <span className='link-primary cl-pointer' onClick={handleShow}>Change password</span>
        <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Change password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group className='mb-3' controlId='currentPassword'>
                        <Form.Label>Current password<span className='required ms-1'>(*)</span></Form.Label>
                        <InputGroup>
                            <Form.Control
                                type={eyeCurrent ? "password" : "text"}
                                autoFocus
                                name='currentPassword'
                                className={errors.currentPassword ? 'input-error': '' }
                                {...register("currentPassword", { required: true })} // pattern: regexSystem.password.pattern.register
                                aria-invalid={errors.currentPassword ? "true" : "false"} 
                            />
                            <InputGroup.Text className='cl-pointer'>{EyeElement('current')}</InputGroup.Text>
                        </InputGroup>
                    </Form.Group>
                    <Form.Group className='mb-3' controlId='newPassword'>
                        <Form.Label>New password<span className='required ms-1'>(*)</span></Form.Label>
                        <InputGroup>
                            <Form.Control
                                type={eyePassword ? "password" : "text"}
                                autoFocus
                                name='newPassword'
                                className={errors.newPassword ? 'input-error': '' }
                                {...register("newPassword", { required: true, pattern: regexSystem.password.pattern.register})}
                                aria-invalid={errors.newPassword ? "true" : "false"} 
                            />
                            <InputGroup.Text className='cl-pointer'>{EyeElement('password')}</InputGroup.Text>
                        </InputGroup>
                    </Form.Group>
                    <Form.Group className='mb-3' controlId='confirmPassword'>
                        <Form.Label>Confirm password<span className='required ms-1'>(*)</span></Form.Label>
                        <InputGroup>
                            <Form.Control
                                type={eyeConfirm ? "password" : "text"}
                                autoFocus
                                name='confirmPassword'
                                className={errors.confirmPassword ? 'input-error': '' }
                                {...register("confirmPassword", { required: true, pattern: regexSystem.password.pattern.register})}
                                aria-invalid={errors.confirmPassword ? "true" : "false"} 
                            />
                            <InputGroup.Text className='cl-pointer'>{EyeElement('confirm')}</InputGroup.Text>
                        </InputGroup>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='default' type='button' onClick={handleClose}><IconX />Cancel</Button>
                <Button variant='primary ms-auto' type='button' onClick={handleSubmit(onSubmit)}><IconDownload />Save</Button>
            </Modal.Footer>
        </Modal>
    </>);
}

export default ModalPassword;