/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import toast, { Toaster } from 'react-hot-toast';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { IconArrowBackUp, IconDownload } from '@tabler/icons';
import _ from 'lodash';
import * as ActionTypes from './actions';
import { getProfile } from './services';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import ModalImage from './ModalImage';
import * as Storage from '../../storage'
import ModalPassword from './ModalPassword';
import { regexSystem } from '../../constant';

const ProfilePage = (props) => {
    const userStorage = JSON.parse(localStorage.getItem(Storage.USER_INFO));
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [userInfo, setUserInfo] = useState(userStorage)
    // Get load data init profile
    useEffect(() => {
        async function fetchDataProfile () {
            const response = await getProfile();
            localStorage.setItem(Storage.USER_INFO, JSON.stringify(response.userInfo));
            setUserInfo(response.userInfo);
        }
        fetchDataProfile();
    }, []);

    const onSubmit = (data) => {
        const params = {
            firstName: data.firstName,
            lastName: data.lastName,
            userName: data.userName,
            email: data.email,
            timeZone: data.timeZone,
            numberPhone: data.numberPhone,
            address: data.address,
            city: data.city,
            zipcode: data.zipcode,
            countryCode: data.countryCode,
            gender: data.gender
        };
        const result = _.mapKeys(params, function (value, key) {
            return _.snakeCase(key)
        });
        updateProfile(result);
    };
    const updateProfile = (params) => {
        props.dispatch({ type: ActionTypes.PROFILE_UPDATE.REQUESTED, detail: params });
    };
    const profileInfo = _.get(props, 'resultProfile.UpdateProfile')
    if (_.get(profileInfo, 'done')) {
        if (_.get(profileInfo, 'error')) {
            const errorMessage = _.get(profileInfo, 'message', [])
            toast.error(errorMessage);
        } else {
            toast.success('Successfully');
        }
    }
    return (<>
        <Header userInfo={userInfo} />
        <div className='page-wrapper mt-55'>
            <div className='page-body'>
                <div className='container-xl'>
                    <Link className='nav-link d-inline' to='/'>
                        <IconArrowBackUp />
                    </Link>
                    <h3 className='card-title col'>Profile</h3>
                    <Row>
                        <Col xs={12} md={3}>
                            <ModalImage />
                            <div className='m-2'>
                                <ModalPassword />
                            </div>
                        </Col>
                        <Col>
                            <Form onSubmit={handleSubmit(onSubmit)} className='row'>
                                <Form.Group className='mb-3 col-md-6 col-12'>
                                    <Form.Label htmlFor='firstName'>First name</Form.Label>
                                    <Form.Control
                                        type='text'
                                        id='firstName'
                                        name='firstName'
                                        defaultValue={userInfo.first_name}
                                        className={errors.firstName ? 'input-error': '' }
                                        {...register("firstName", { required: true })}
                                        aria-invalid={errors.firstName ? "true" : "false"}
                                    />
                                </Form.Group>
                                <Form.Group className='mb-3 col-md-6 col-12'>
                                    <Form.Label htmlFor='lastName'>Last name</Form.Label>
                                    <Form.Control
                                        type='text'
                                        id='lastName'
                                        name='lastName'
                                        defaultValue={userInfo.last_name}
                                        className={errors.lastName ? 'input-error': '' }
                                        {...register("lastName", { required: true })}
                                        aria-invalid={errors.lastName ? "true" : "false"} 
                                    />
                                </Form.Group>
                                <Form.Group className='mb-3 col-md-6 col-12'>
                                    <Form.Label htmlFor='userName'>User name</Form.Label>
                                    <Form.Control
                                        type='text'
                                        id='userName'
                                        name='userName'
                                        defaultValue={userInfo.user_name}
                                        className={errors.userName ? 'input-error': '' }
                                        {...register("userName", { required: true })}
                                        aria-invalid={errors.userName ? "true" : "false"} 
                                    />
                                </Form.Group>
                                <Form.Group className='mb-3 col-md-6 col-12'>
                                    <Form.Label htmlFor='email'>Email</Form.Label>
                                    <Form.Control
                                        type='text'
                                        id='email'
                                        name='email'
                                        value={userInfo.email}
                                        disabled
                                    />
                                </Form.Group>
                                <Form.Group className='mb-3 col-md-6 col-12'>
                                    <Form.Label htmlFor='numberPhone'>Number phone</Form.Label>
                                    <Form.Control
                                        type='text'
                                        id='numberPhone'
                                        name='numberPhone'
                                        placeholder='(00) 0000-0000' 
                                        defaultValue={userInfo.number_phone}
                                        className={errors.numberPhone ? 'input-error': '' }
                                        {...register("numberPhone", { required: true, pattern: regexSystem.phone.pattern.register})}
                                        aria-invalid={errors.numberPhone ? "true" : "false"} 
                                    />
                                </Form.Group>
                                <Form.Group className='mb-3 col-md-6 col-12'>
                                    <Form.Label htmlFor='role'>Role</Form.Label>
                                    <Form.Select id='role'
                                        name='role'
                                        disabled
                                        value={userInfo.role}>
                                        {userInfo.role === 1 && <option value={1}>User</option>}
                                        {userInfo.role === 2 && <option value={2}>Assistant</option>}
                                        {userInfo.role === 3 && <option value={3}>Admin</option>}
                                        {userInfo.role === 4 && <option value={4}>Super Admin</option>}
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group className='mb-3 col-md-6 col-12'>
                                    <Form.Label htmlFor='address'>Address</Form.Label>
                                    <Form.Control
                                        type='text'
                                        id='address'
                                        name='address'
                                        defaultValue={userInfo.address}
                                        className={errors.address ? 'input-error': '' }
                                        {...register("address", { required: true, pattern: regexSystem.address.pattern.register })}
                                        aria-invalid={errors.address ? "true" : "false"}
                                    />
                                </Form.Group>
                                <Form.Group className='mb-3 col-md-6 col-12'>
                                    <Form.Label htmlFor='gender'>Gender</Form.Label>
                                    <Form.Select
                                        id='gender' 
                                        name='gender'
                                        defaultValue={userInfo.gender}
                                        aria-invalid={errors.gender ? "true" : "false"}
                                        {...register('gender', { required: true } )}>
                                        <option value={0}>Other</option>
                                        <option value={1}>Male</option>
                                        <option value={2}>Female</option>
                                    </Form.Select>
                                </Form.Group>
                                <div>
                                    <Button 
                                        variant='primary'
                                        className='btn btn-primary'
                                        type='button'
                                        onClick={handleSubmit(onSubmit)} >
                                        <IconDownload /> Save
                                    </Button>
                                </div>
                            </Form>
                        </Col>
                    </Row>
                    <Toaster
                        position='top-center'
                        reverseOrder={false}
                    />
                </div>
            </div>
            <Footer />
        </div>
    </>);
};

const mapStateToProps = (state) => ({
        resultProfile: state.profile
});

export default connect(mapStateToProps)(ProfilePage);