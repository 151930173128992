/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import toast, { Toaster } from 'react-hot-toast';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import InputGroup from 'react-bootstrap/InputGroup';
import _ from 'lodash';
import { IconX, IconDownload, IconSquarePlus, IconEdit } from '@tabler/icons';
import { putProduct } from './services';
import { typeObject } from '../../constant';
const UpdateProduct = ({ fetchDataProduct, listA, data }) => {
    const { register, handleSubmit, setError, formState: { errors } } = useForm();
    const [attributes, setAttributes] = useState([]);
    const srp = data.srp_id;
    const disabled = false;
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    useEffect(() => {
        if (_.get(data, 'srp_id.attributes')) {
            const objAttribute = JSON.parse(_.get(data, 'srp_id.attributes'));
            if (objAttribute.constructor === Object) {
                let arrayAttribute = []
                for (const [key, value] of Object.entries(objAttribute)) {
                    arrayAttribute.push({
                        code: key,
                        value: value
                    });
                }
                setAttributes(arrayAttribute);
            }
            if (objAttribute.constructor === Array) {
                setAttributes(objAttribute);
            }
        }
    }, [data]);

    const addAttribute = () => {
        const dataUpdate = [...attributes, listA[0]];
        setAttributes(dataUpdate);
    }
    const deleteAttribute = (e, idx) => {
        if (_.size(attributes) > 1) {
            const dataUpdate = attributes.filter(function (item, key) {
                return key !== idx;
            })
            setAttributes(dataUpdate);
        }
    }
    const handleChangeInput = (event, code) => {
        var dataAttribute = [...attributes];
        _.map(dataAttribute, ele => {
            if(ele.code === code) {
                ele.value = event.target.value;
            }
            return ele;
        });
        setAttributes(dataAttribute);
    }
    const handleChange = (event) => {
        let data_name = event.target.name.split('-');
        if (data_name[1]) {
            const attrId = data_name[1];
            const dataAttribute = [...attributes];
            if (['value'].includes(data_name[0])) {
                if (event.target.value.trim() === '') {
                    dataAttribute[attrId]['err_' + data_name[0]] = true;
                } else {
                    dataAttribute[attrId]['err_' + data_name[0]] = false;
                }
                dataAttribute[attrId][data_name[0]] = event.target.value;
                setAttributes(dataAttribute);
            }
        }
    }
    const handleAttribute = (event, idx) => {
        const attr = JSON.parse(event.target.options[event.target.selectedIndex].dataset.attr);
        const dataUpdate = _.map(attributes, (item, key) => {
            if (key === idx) {
                item = attr;
            }
            return item;
        })
        setAttributes(dataUpdate);
    }
    const onSubmit = async (dataForm) => {
        let attributeObject = _.uniqBy([...attributes], 'label');
        const attributeData = _.filter(attributeObject, (obj) => {
            if (!obj.value){
                if ([typeObject.SIMPLE_SELECT, typeObject.MULTI_SELECT].includes(obj.typeData)) {
                    obj.value = obj.selectOption[0].value;
                    return obj;
                } else if ([typeObject.BOOLEAN].includes(obj.typeData)) {
                    obj.value = 'YES';
                    return obj;
                }
            } else {
                return obj;
            }
        });
        let dataProduct = {
            id: data.id,
            description: dataForm.description,
            meta_description: dataForm.metaDescription,
            meta_keyword: dataForm.metaKeyword,
            meta_title: dataForm.metaTitle,
            sku: dataForm.sku,
            attributes: attributeData
        }
        dataProduct = _.pickBy(dataProduct, v => !_.isNull(v) && !_.isUndefined(v));
        const response = await putProduct(dataProduct);
        if (!_.has(response, 'error')) {
            toast.success('Successfully');
            fetchDataProduct();
            setShow(false);
        } else {
            toast.error(response.errMessage || 'Update failed!');
            setError('sku');
        }
    }
    return (
        <>
            <span className='text-primary me-2 cl-pointer' onClick={handleShow}><IconEdit/></span>
            <Modal show={show} onHide={handleClose} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>Update a product</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <div className='d-table'>
                            <Form.Switch
                                className='mb-3'
                                type='switch'
                                autoFocus
                                defaultChecked={srp ? srp.isDescription : 1}
                                name='description'
                                label='Description'
                                {...register('description')}
                            />
                            <Form.Switch
                                className='mb-3'
                                type='switch'
                                autoFocus
                                defaultChecked={srp ? srp.isMetaDescription : 1}
                                name='metaDescription'
                                label='Meta Description'
                                {...register('metaDescription')}
                            />
                            <Form.Switch
                                className='mb-3'
                                type='switch'
                                autoFocus
                                defaultChecked={srp ? srp.isTitle : 1}
                                name='metaTitle'
                                label='Title'
                                {...register('metaTitle')}
                            />
                            <Form.Switch
                                className='mb-3' 
                                type='switch'
                                autoFocus
                                defaultChecked={srp ? srp.isKeyword : 1}
                                name='metaKeyword'
                                label='Keyword'
                                {...register('metaKeyword')}
                            />
                        </div>
                        <hr/>
                        <Form.Group as={Row} className='mb-2' controlId='sku'>
                            <Form.Label column sm='2'>Sku</Form.Label>
                            <Col sm='10'>
                                <Form.Control
                                    type='text'
                                    name='sku'
                                    defaultValue={data.sku}
                                    className={errors.sku ? 'input-error': '' }
                                    {...register('sku', { required: true })}
                                    aria-invalid={errors.sku ? 'true' : 'false'} 
                                />
                            </Col>
                        </Form.Group>
                        <Form.Label>Attribute:</Form.Label>
                        {Array.isArray(attributes) && _.size(attributes) > 0 && attributes.map((val, idx) => {
                            let labelId = `label-${idx}`;
                            let valueId = `value-${idx}`;
                            return <div key={idx} className='form-attributes mr-top-30'>
                                <InputGroup className='mb-3'>
                                    <InputGroup.Text id={labelId}>Label & value</InputGroup.Text>
                                    {!val.id && <>
                                        <Form.Control
                                            name={labelId}
                                            value={_.upperFirst(val.label) || _.upperFirst(val.code)}
                                            disabled
                                            readOnly
                                        />
                                        <Form.Control
                                            name={valueId}
                                            defaultValue={val.value}
                                            onChange={e => handleChangeInput(e, val.code)}
                                        />
                                    </>}
                                    {val.id && <>
                                        <Form.Select
                                            name={labelId}
                                            value={val.id}
                                            onChange={(e) => handleAttribute(e, idx)}
                                        >
                                            {Array.isArray(listA) && listA.map((a, k) =>
                                                <option key={k} value={a.id} data-attr={JSON.stringify(a)}>{a.label || a.code}</option>
                                            )}
                                        </Form.Select>
                                        {_.isEqual(val.typeData, typeObject.STRING) &&<Form.Control
                                            name={valueId}
                                            defaultValue={val.value}
                                            onChange={(e) => handleChange(e)}
                                        />}
                                        {_.isEqual(val.typeData, typeObject.DATE) &&<Form.Control
                                            name={valueId}
                                            defaultValue={val.value}
                                            type='date'
                                            onChange={(e) => handleChange(e)}
                                        />}
                                        {_.isEqual(val.typeData, typeObject.BOOLEAN) && <Form.Select 
                                            name={valueId}
                                            defaultValue={val.value}
                                            onChange={(e) => handleChange(e)}>
                                            <option value='YES'>YES</option>
                                            <option value='NO'>NO</option>
                                        </Form.Select>}
                                        {[typeObject.SIMPLE_SELECT, typeObject.MULTI_SELECT].includes(val.typeData) && <Form.Select
                                            name={valueId}
                                            defaultValue={val.value}
                                            onChange={(e) => handleChange(e)}>
                                            {Array.isArray(val.selectOption) && val.selectOption.map((o, k) =>
                                                <option key={k} value={o.value}>{o.value}</option>
                                            )}
                                        </Form.Select>}
                                        {_.isEqual(val.typeData, typeObject.NUMBER) && <Form.Control 
                                            type='number'
                                            name={valueId}
                                            defaultValue={val.value}
                                            onChange={(e) => handleChange(e)}
                                        />}
                                    </>}
                                    <InputGroup.Text
                                        className='cl-pointer col-auto required'
                                        disabled={disabled}
                                        onClick={(e) => deleteAttribute(e, idx)}>
                                        <IconX />
                                    </InputGroup.Text>
                                </InputGroup>
                            </div>
                        })}
                        <span><IconSquarePlus className='link-primary cl-pointer' size={24} onClick={addAttribute} /></span>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='default' type='button' onClick={handleClose}><IconX />Cancel</Button>
                    <Button variant='primary ms-auto' type='submit' onClick={handleSubmit(onSubmit)}><IconDownload/>Save</Button>
                </Modal.Footer>
            </Modal>
            <Toaster
                position='top-center'
                reverseOrder={false}
            />
        </>
    );
}

export default UpdateProduct;